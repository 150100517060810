const propertyReducer = (
  state = {
    data: [],
    loading: false,
    error: false,
  },
  action
) => {
  switch (action.type) {
    case "GET_PROPERTY_LOADING":
      return { ...state, loading: true, error: false };
    case "GET_PROPERTY_SUCCESS":
      return { ...state, data: action.data, loading: false, error: false };
    case "GET_PROPERTY_FAIL":
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
};

export default propertyReducer;
