import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import {
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
  AiOutlineUser,
  AiOutlineDashboard,
} from "react-icons/ai";
import { MdOutlineContactPage } from "react-icons/md";
import { Button, Card, Dropdown, Form, NavDropdown } from "react-bootstrap";
import { HiLanguage } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import { CustomModal } from "../customModal/CustomModal";
import history from "../../shared/history";

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [isOpen, setisOpen] = useState(false);
  const [languageModal, setlanguageModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    document.body.dir = i18n.dir();
    theme.direction = i18n.dir();
    setlanguageModal(false);
  };

  const open = () => {
    setisOpen(true);
  };

  const close = () => {
    setisOpen(false);
  };
  const closelanguageModal = () => {
    setlanguageModal(false);
  };
  const signout = () => {
    localStorage.clear();
    history.push("/auth");
    window.location.reload();
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        <Navbar.Brand
          href={
            JSON.parse(localStorage.getItem("user"))?.roleName === "ADMIN"
              ? "/admin"
              : "/home"
          }
          className="d-flex"
        >
          <img
            src={require("../../assets/images/home.png")}
            className="img-fluid logo"
            alt="brand"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            {JSON.parse(localStorage.getItem("user"))?.roleName === "ADMIN" && (
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/admin"
                  onClick={() => updateExpanded(false)}
                >
                  <AiOutlineDashboard style={{ marginBottom: "2px" }} />{" "}
                  {t("dashboard")}
                </Nav.Link>
              </Nav.Item>
            )}

            {JSON.parse(localStorage.getItem("user"))?.roleName === "ADMIN" && (
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/admin-properties"
                  onClick={() => updateExpanded(false)}
                >
                  <AiOutlineDashboard style={{ marginBottom: "2px" }} />{" "}
                  {t("properties")}
                </Nav.Link>
              </Nav.Item>
            )}

            {JSON.parse(localStorage.getItem("user"))?.roleName !== "ADMIN" && (
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/home"
                  onClick={() => updateExpanded(false)}
                >
                  <AiOutlineHome style={{ marginBottom: "2px" }} /> {t("home")}
                </Nav.Link>
              </Nav.Item>
            )}

            {JSON.parse(localStorage.getItem("user"))?.roleName === "AGENT" && (
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/dashboard"
                  onClick={() => updateExpanded(false)}
                >
                  <AiOutlineDashboard style={{ marginBottom: "2px" }} />{" "}
                  {t("dashboard")}
                </Nav.Link>
              </Nav.Item>
            )}

            {JSON.parse(localStorage.getItem("user"))?.roleName ===
              "CUSTOMER" && (
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/customer-dashboard"
                  onClick={() => updateExpanded(false)}
                >
                  <AiOutlineDashboard style={{ marginBottom: "2px" }} />{" "}
                  {t("dashboard")}
                </Nav.Link>
              </Nav.Item>
            )}

            {JSON.parse(localStorage.getItem("user"))?.roleName !== "ADMIN" && (
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/about"
                  onClick={() => updateExpanded(false)}
                >
                  <AiOutlineFundProjectionScreen
                    style={{ marginBottom: "2px" }}
                  />{" "}
                  {t("about")}
                </Nav.Link>
              </Nav.Item>
            )}

            {JSON.parse(localStorage.getItem("user"))?.roleName !== "ADMIN" && (
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="/contact"
                  onClick={() => updateExpanded(false)}
                >
                  <MdOutlineContactPage style={{ marginBottom: "2px" }} />{" "}
                  {t("contact")}
                </Nav.Link>
              </Nav.Item>
            )}

            <NavDropdown
              id="nav-dropdown-dark-example"
              title={
                <>
                  <AiOutlineUser style={{ marginBottom: "2px" }} />{" "}
                  {t("account")}
                </>
              }
              menuVariant="light"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="WelcomeUserText">
                  {t("welcome")},{" "}
                  {JSON.parse(localStorage.getItem("user"))?.userName === null
                    ? JSON.parse(localStorage.getItem("user"))?.firstName
                    : JSON.parse(localStorage.getItem("user"))?.userName}
                </span>
              </div>
              <NavDropdown.Item
                onClick={() => updateExpanded(false)}
                href="/account"
              >
                {t("changepassword")}
              </NavDropdown.Item>

              <NavDropdown.Item
                onClick={() => {
                  setlanguageModal(true);
                  updateExpanded(false);
                }}
              >
                {t("changelanguage")}
              </NavDropdown.Item>

              <NavDropdown.Item
                style={{ color: "red" }}
                onClick={() => {
                  open();
                  updateExpanded(false);
                }}
              >
                {t("signout")}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
      <CustomModal
        size="md"
        title={t("signout")}
        show={isOpen}
        onHide={close}
        footer={true}
        handleDelete={signout}
      >
        <div>
          <Card.Text>{t("signoutconfirm")}</Card.Text>
        </div>
      </CustomModal>

      <CustomModal
        size="md"
        title={t("changelanguage")}
        show={languageModal}
        onHide={closelanguageModal}
        footer={false}
      >
        <div>
          <Card.Text>{t("selectlanguage")}</Card.Text>
        </div>
        <Form onSubmit={onSubmit}>
          <div className="mb-3">
            <Form.Check
              onClick={() => setSelectedLanguage("en")}
              inline
              label="English"
              name="group1"
              type="radio"
              checked={selectedLanguage === "en" ? true : false}
            />
            <Form.Check
              onClick={() => setSelectedLanguage("he")}
              inline
              label="Hebrew"
              name="group1"
              type="radio"
              checked={selectedLanguage === "he" ? true : false}
            />
          </div>
        </Form>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            style={{ marginLeft: "1rem" }}
            onClick={() => handleLanguageChange(selectedLanguage)}
          >
            {t("update")}
          </Button>
        </div>
      </CustomModal>
    </Navbar>
  );
}

export default NavBar;
