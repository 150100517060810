import { combineReducers } from "redux";

import propertyReducer from "./propertyReducer";
import agentPropertyReducer from "./agentPropertyReducer";

const reducers = combineReducers({
  propertyReducer,
  agentPropertyReducer,
});

export { reducers };
