import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaHandPointRight } from "react-icons/fa";
import Footer from "../../components/footer/Footer";
import NavBar from "../../components/navBar/NavBar";

export default function Contact() {
  const { t } = useTranslation();
  return (
    <section>
      <NavBar />
      <Container fluid className="home-section" id="home">
        <Row className="home-header-row">
          <Col md={8} className="home-header">
            <span
              style={{
                paddingBottom: 15,
                fontSize: "100px",
                textAlign: "center",
                color: "#fff",
              }}
              className="heading"
            >
              {t("contactus")}
            </span>
          </Col>
        </Row>
      </Container>
      <Container
        style={{ minHeight: window.innerHeight - 100 }}
        fluid
        className="home-about-section"
      >
        <Container>
          <Row style={{ justifyContent: "center", padding: "10px" }}>
            <Col
              md={12}
              style={{
                justifyContent: "center",
                paddingTop: "30px",
                paddingBottom: "30px",
              }}
            >
              <h1 style={{ fontSize: "3em", paddingBottom: "20px" }}>
                {t("have")} <strong className="purple">{t("question")}</strong>
              </h1>
            </Col>
          </Row>
          <Row className="mb-5" md={3} xs={1}>
            <Col className="mb-xs-3 mb-sm-3 mb-3">
              <blockquote className="blockquote mb-0">
                <strong className="purple">{t("ouraddress")}</strong>
              </blockquote>
              <blockquote className="blockquote mb-0">
                <p style={{ textAlign: "center", color: "#000" }}>
                  9201 West Broadway Ave, Suite 615 Minneapolis, MN 55445
                </p>
              </blockquote>
            </Col>
            <Col className="mb-xs-3 mb-sm-3 mb-3">
              <blockquote className="blockquote mb-0">
                <strong className="purple">{t("phone")}</strong>
              </blockquote>
              <blockquote className="blockquote mb-0">
                <p style={{ textAlign: "center", color: "#000" }}>
                  (866) 443-7637
                </p>
              </blockquote>
            </Col>
            <Col className="mb-xs-3 mb-sm-3 mb-3">
              <blockquote className="blockquote mb-0">
                <strong className="purple">{t("businesshours")}</strong>
              </blockquote>
              <blockquote className="blockquote mb-0">
                <p style={{ textAlign: "center", color: "#000" }}>
                  Monday-Friday, 9:00 AM – 5:00 PM Central Time
                </p>
              </blockquote>
            </Col>
          </Row>
        </Container>
      </Container>
      <Footer />
    </section>
  );
}
