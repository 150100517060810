import React from "react";

const HorizontalBar = () => {
  return (
    <div
      style={{
        width: "75%",
        height: "1px",
        backgroundColor: "#149777",
        margin: "0 auto",
        marginTop: "1rem",
        marginBottom: "1rem",
      }}
    ></div>
  );
};

export default HorizontalBar;
