import api from "./axios";

const register = (body) => {
  return new Promise((resolve, reject) => {
    api("post", null, `/user/register`, null, body, null)
      .then((response) => {
        resolve(response.data.results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const login = async (body) => {
  return new Promise((resolve, reject) => {
    api("post", null, `/user`, null, body, null)
      .then((response) => {
        resolve(response.data.results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const loginwithFacebook = async (username) => {
  return new Promise((resolve, reject) => {
    api("post", null, `/user/auto/facebook/${username}`, null, null, null)
      .then((response) => {
        resolve(response.data.results);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createPost = async (body) => {
  var myHeaders = {
    "Content-Type": "multipart/form-data",
  };
  return new Promise((resolve, reject) => {
    api("post", myHeaders, `/productDetails`, null, body, "")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getPosts = async (body) => {
  return new Promise((resolve, reject) => {
    api("post", null, `/productDetails/search`, null, body, null)
      .then((response) => {
        resolve(response.data.results.productDetails);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const uploadDocuments = async (body) => {
  var myHeaders = {
    "Content-Type": "multipart/form-data",
  };
  return new Promise((resolve, reject) => {
    api("post", myHeaders, `/productDetails/pdfWord`, null, body, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getAllSalesByUserId = async (id) => {
  return new Promise((resolve, reject) => {
    api("get", null, `/salesHistory/buyer/${id}`, null, null, null)
      .then((response) => {
        resolve(response.data.results.salesHistory);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getFavouritesById = async (id) => {
  return new Promise((resolve, reject) => {
    api("get", null, `/favList/${id}`, null, null, null)
      .then((response) => {
        resolve(response.data.results.salesHistory);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getAllUsers = async () => {
  return new Promise((resolve, reject) => {
    api("get", null, `/user`, null, null, null)
      .then((response) => {
        resolve(response.data.results.User);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateUserStatus = async (id) => {
  return new Promise((resolve, reject) => {
    api("put", null, `/user/status/${id}`, null, null, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getPostsByUser = async (email) => {
  return new Promise((resolve, reject) => {
    api("get", null, `/productDetails/user/${email}`, null, null, null)
      .then((response) => {
        resolve(response.data.results.productDetails);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateProperty = async (body) => {
  var myHeaders = {
    "Content-Type": "multipart/form-data",
  };
  return new Promise((resolve, reject) => {
    api("put", myHeaders, `/productDetails`, null, body, "")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deletePropery = async (id) => {
  return new Promise((resolve, reject) => {
    api("delete", null, `/productDetails/${id}`, null, null, "")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteFile = async (id) => {
  return new Promise((resolve, reject) => {
    api("delete", null, `/productDetails/pdfWord/${id}`, null, null, "")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const changePassword = async (body) => {
  return new Promise((resolve, reject) => {
    api("put", null, `/changePassword`, null, body, "")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const buyProperty = async (body) => {
  return new Promise((resolve, reject) => {
    api("post", null, `/salesHistory`, null, body, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getlocations = async (email) => {
  return new Promise((resolve, reject) => {
    api("get", null, `/location`, null, null, null)
      .then((response) => {
        resolve(response.data.results.loaction);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getpendingRequest = async (id) => {
  return new Promise((resolve, reject) => {
    api("get", null, `/salesHistory/agent/${id}`, null, null, null)
      .then((response) => {
        resolve(response.data.results.salesHistory);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getApprovedProperties = async (id) => {
  return new Promise((resolve, reject) => {
    api("get", null, `/salesHistory/confirmed/agent/${id}`, null, null, null)
      .then((response) => {
        resolve(response.data.results.salesHistory);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const addFavourite = async (body) => {
  return new Promise((resolve, reject) => {
    api("post", null, `/favList`, null, body, null)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteFavourite = async (body) => {
  return new Promise((resolve, reject) => {
    api("delete", null, `/favList`, null, body, "")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateRequestStatus = async (id) => {
  return new Promise((resolve, reject) => {
    api("put", null, `/salesHistory/status/${id}`, null, null, "")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const resetPassword = async (email) => {
  return new Promise((resolve, reject) => {
    api("put", null, `/forgot-mail/${email}`, null, null, "")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const imageURL = `${process.env.REACT_APP_API_URI}/productDetails/file/`;
const pdfURL = `${process.env.REACT_APP_API_URI}/productDetails/file/pdfWord/`;
export {
  register,
  login,
  createPost,
  getPosts,
  getPostsByUser,
  updateProperty,
  deletePropery,
  imageURL,
  changePassword,
  buyProperty,
  getAllUsers,
  updateUserStatus,
  loginwithFacebook,
  getlocations,
  getAllSalesByUserId,
  addFavourite,
  getFavouritesById,
  deleteFavourite,
  uploadDocuments,
  deleteFile,
  getpendingRequest,
  updateRequestStatus,
  pdfURL,
  getApprovedProperties,
  resetPassword,
};
