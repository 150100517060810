import { useState } from "react";

const useGeocoder = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const [suggestions, setSuggestions] = useState([]);

  const handleChange = async (event) => {
    setValue(event);

    try {
      const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${event}.json?access_token=${process.env.REACT_APP_MAPBOX_KEY}&autocomplete=true`;
      const response = await fetch(endpoint);
      const results = await response.json();
      setSuggestions(results?.features);
    } catch (error) {}
  };

  return {
    value,
    onInputChange: handleChange,
    setValue,
    suggestions,
    setSuggestions,
  };
};

export default useGeocoder;
