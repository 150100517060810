import "mapbox-gl/dist/mapbox-gl.css";
import Map, {
  Marker,
  NavigationControl,
  FullscreenControl,
  GeolocateControl,
} from "react-map-gl";
import mapboxgl from "mapbox-gl";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

function MapBox(props) {
  const { lat, lng } = props;

  return (
    <Map
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_KEY}
      style={{
        width: "100%",
        height: "500px",
      }}
      initialViewState={{
        longitude: lng,
        latitude: lat,
        zoom: 16,
      }}
      mapStyle="mapbox://styles/mapbox/streets-v9"
    >
      <Marker longitude={lng} latitude={lat} />
      <NavigationControl position="bottom-right" />
      <FullscreenControl />
      <GeolocateControl />
    </Map>
  );
}

export default MapBox;
