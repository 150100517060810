import "./App.css";
import "./css/style.css";
import Home from "./pages/home/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import { useTranslation } from "react-i18next";
import withRoot from "./shared/withRoot";
import { useEffect, useState } from "react";
import Contact from "./pages/contact/Contact";
import About from "./pages/about/About";
import Account from "./pages/account/Account";
import Administrator from "./pages/admin/Administrator";
import UserDashboard from "./pages/userDashboard/UserDashboard";
import AdminProperties from "./pages/adminProperties/AdminProperties";

function App() {
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();

  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  const token = JSON.parse(localStorage.getItem("user"));
  const role = JSON.parse(localStorage.getItem("user"))?.roleName;
  return (
    <Router>
      <div className="App" id={"scroll"}>
        {/* <ScrollToTop /> */}
        <Routes>
          <Route
            path="/"
            element={
              token && role !== "ADMIN" ? (
                <Navigate to="home" />
              ) : (
                <Navigate to="auth" />
              )
            }
          />
          <Route
            path="/"
            element={
              token && role === "ADMIN" ? (
                <Navigate to="admin" />
              ) : (
                <Navigate to="auth" />
              )
            }
          />
          <Route
            path="/home"
            element={
              token && role !== "ADMIN" ? <Home /> : <Navigate to="../auth" />
            }
          />
          <Route
            path="/dashboard"
            element={
              token && role === "AGENT" ? (
                <Dashboard />
              ) : (
                <Navigate to="../auth" />
              )
            }
          />
          <Route
            path="/customer-dashboard"
            element={
              token && role === "CUSTOMER" ? (
                <UserDashboard />
              ) : (
                <Navigate to="../auth" />
              )
            }
          />
          <Route
            path="/contact"
            element={
              token && role !== "ADMIN" ? (
                <Contact />
              ) : (
                <Navigate to="../auth" />
              )
            }
          />
          <Route
            path="/about"
            element={
              token && role !== "ADMIN" ? <About /> : <Navigate to="../auth" />
            }
          />
          <Route
            path="/account"
            element={token ? <Account /> : <Navigate to="../auth" />}
          />
          <Route
            path="/auth"
            element={
              token ? (
                <Navigate to={role === "ADMIN" ? "../admin" : "../home"} />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/admin"
            element={
              token && role === "ADMIN" ? (
                <Administrator />
              ) : (
                <Navigate to="../auth" />
              )
            }
          />
          <Route
            path="/admin-properties"
            element={
              token && role === "ADMIN" ? (
                <AdminProperties />
              ) : (
                <Navigate to="../auth" />
              )
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default withRoot(App);
