import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Footer from "../../components/footer/Footer";
import NavBar from "../../components/navBar/NavBar";
import { CustomModal } from "../../components/customModal/CustomModal";
import history from "../../shared/history";
import { changePassword } from "../../api/apis";
import { toast, Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default function Account() {
  const { t } = useTranslation();

  const [validated, setValidated] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [isMatched, setIsMatched] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleTogglePasswordVisibility = () => setShowPassword(!showPassword);
  const handleToggleNewPasswordVisibility = () =>
    setShowNewPassword(!showNewPassword);
  const handleToggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmNewPasswordChange = (event) => {
    if (event.target.value === newPassword) {
      setIsMatched(true);
    } else {
      setIsMatched(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false || !isMatched) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      const userId = JSON.parse(localStorage.getItem("user"))?.id;
      const payload = {
        id: userId,
        oldPassword: event.target[0]?.value,
        newPassword: event.target[1]?.value,
      };
      changePasswordFunction(payload);
      form.reset();
    }
  };

  const changePasswordFunction = async (data) => {
    try {
      await changePassword(data);
      toast.success("Password updated successfully.");
      setValidated(false);
    } catch (error) {
      setValidated(false);
      toast.error(error?.data?.validationFailures[0]?.message);
    }
  };

  const open = () => {
    setisOpen(true);
  };

  const close = () => {
    setisOpen(false);
  };

  const signout = () => {
    localStorage.clear();
    window.localStorage.removeItem("facebookAccessToken");
    history.push("/auth");
    window.location.reload();
  };

  return (
    <section>
      <Toaster />
      <NavBar />
      <Container fluid className="home-section" id="home">
        <Row className="home-header-row">
          <Col md={8} className="home-header">
            <span
              style={{
                paddingBottom: 15,
                fontSize: "100px",
                textAlign: "center",
                color: "#fff",
              }}
              className="heading"
            >
              {t("accountsettings")}
            </span>
          </Col>
        </Row>
      </Container>
      <Container
        style={{ minHeight: window.innerHeight - 100 }}
        fluid
        className="home-about-section"
      >
        <Container>
          <Row
            md={2}
            xs={1}
            sm={1}
            style={{
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "3rem",
            }}
          >
            <Col
              md={6}
              style={{
                justifyContent: "center",
              }}
            >
              <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
                {t("change")}{" "}
                <strong className="purple">{t("password")}</strong>
              </h1>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="validationCustom03"
                  >
                    <Form.Label column sm="4">
                      {t("currentpassword")}
                    </Form.Label>
                    <Col sm="8">
                      <InputGroup>
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          placeholder={t("currentpassword")}
                          required
                        />
                        <InputGroup.Text
                          style={{ cursor: "pointer" }}
                          onClick={handleTogglePasswordVisibility}
                        >
                          <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                            style={{ cursor: "pointer" }}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </Col>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="validationCustom03"
                  >
                    <Form.Label column sm="4">
                      {t("newpassword")}
                    </Form.Label>
                    <Col sm="8">
                      <InputGroup>
                        <Form.Control
                          type={showNewPassword ? "text" : "password"}
                          placeholder={t("newpassword")}
                          required
                          onChange={handleNewPasswordChange}
                        />
                        <InputGroup.Text
                          style={{ cursor: "pointer" }}
                          onClick={handleToggleNewPasswordVisibility}
                        >
                          <FontAwesomeIcon
                            icon={showNewPassword ? faEyeSlash : faEye}
                            style={{ cursor: "pointer" }}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </Col>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="validationCustom03"
                  >
                    <Form.Label column sm="4">
                      {t("confirmpassword")}
                    </Form.Label>
                    <Col sm="8">
                      <InputGroup>
                        <Form.Control
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder={t("reenterpassword")}
                          required
                          onChange={handleConfirmNewPasswordChange}
                        />
                        <InputGroup.Text
                          style={{ cursor: "pointer" }}
                          onClick={handleToggleConfirmPasswordVisibility}
                        >
                          <FontAwesomeIcon
                            icon={showConfirmPassword ? faEyeSlash : faEye}
                            style={{ cursor: "pointer" }}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                      {!isMatched && (
                        <span
                          style={{
                            color: "red",
                            fontSize: "0.9rem",
                          }}
                        >
                          {t("passwordnotmatch")}
                        </span>
                      )}
                    </Col>
                  </Form.Group>
                </Row>

                <Button type="submit">{t("update")}</Button>
              </Form>
            </Col>
            {/* <Col md={5} className="about-img mt-xs-4 ">
              <Button onClick={open} className="signoutButton">
                {t("signout")}
              </Button>
            </Col> */}
          </Row>
        </Container>
      </Container>
      <CustomModal
        size="md"
        title={t("signout")}
        show={isOpen}
        onHide={close}
        footer={true}
        handleDelete={signout}
      >
        <div>
          <Card.Text>{t("signoutconfirm")}</Card.Text>
        </div>
      </CustomModal>
      <Footer />
    </section>
  );
}
