const agentPropertyReducer = (
  state = {
    data: [],
    loading: false,
    error: false,
  },
  action
) => {
  switch (action.type) {
    case "GET_PROPERTY_BY_USER_LOADING":
      return { ...state, loading: true, error: false };
    case "GET_PROPERTY_BY_USER_SUCCESS":
      return { ...state, data: action.data, loading: false, error: false };
    case "GET_PROPERTY_BY_USER_FAIL":
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
};

export default agentPropertyReducer;
