import React from "react";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { imageURL } from "../../api/apis";
import { productType } from "../../shared/constants";

export default function AgentPropertyCard(props) {
  const {
    onClick,
    title,
    price,
    country,
    city,
    type,
    onEditClick,
    onDeleteClick,
    images,
    isAgent = true,
    isSold,
    isUserDashboard = false,
    isBuy = false,
    percentage,
    isHaveStatus = null,
    isShowBuy = false,
    onClickBuy,
  } = props;
  const { t } = useTranslation();
  return (
    <Card className="project-card-view">
      <Card.Img
        onClick={onClick}
        variant="top"
        src={
          images[0]?.name
            ? imageURL + images[0]?.name
            : require("../../assets/images/no-image.jpg")
        }
        style={{ height: "12rem", width: "100%" }}
      />

      {isHaveStatus !== null && (
        <span
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingTop: "1rem",
            paddingLeft: "1rem",
          }}
          className="SaleOrRentTextI"
        >
          <Badge
            style={{ backgroundColor: "#009270" }}
            color="#009270"
            className={
              isHaveStatus === "PENDING" ? "SaleOrRentII" : "SaleOrRentIIGreen"
            }
          >
            {isHaveStatus === "PENDING"
              ? "AWAITING"
              : isHaveStatus === "CONFIRMED"
              ? "CONFIRMED"
              : ""}
          </Badge>
        </span>
      )}
      <Card.Body onClick={onClick} style={{ textAlign: "left" }}>
        <blockquote className="blockquote mb-0">
          <strong className="purple">
            {t("dollar")} {price}
          </strong>
        </blockquote>
        <blockquote className="blockquote mb-0">
          <p
            style={{
              textAlign: "left",
              color: "#000",
              fontSize: "0.9em",
              fontWeight: "bold",
            }}
          >
            {title}
          </p>
        </blockquote>
        <blockquote
          style={{ gap: "0.5rem" }}
          className="blockquote d-flex mb-0"
        >
          <i className="fa fa-map-marker GreenColor"></i>
          <p
            style={{
              textAlign: "left",
              color: "#000",
              fontSize: "0.7em",
            }}
          >
            {city}, {country}
          </p>
        </blockquote>

        {isSold && (
          <span className="SaleOrRentText">
            <Badge className="SaleOrRent red-badge-bg" bg="#FF0000">
              {t("sold")}
            </Badge>
          </span>
        )}

        {type === productType.RENT && !isSold ? (
          <span className="SaleOrRentText">
            <Badge className="SaleOrRent" bg="#149777">
              {t("forrent")}
            </Badge>
          </span>
        ) : type === productType.SELL && !isSold ? (
          <span className="SaleOrRentText">
            <Badge className="SaleOrRent" bg="#149777">
              {t("forsale")}
            </Badge>
          </span>
        ) : (
          ""
        )}
        {isBuy && (
          <span className="SaleOrRentText">
            <Badge className="SaleOrRentI" bg="#149777">
              {percentage} %
            </Badge>
          </span>
        )}
      </Card.Body>

      {isAgent && (
        <div className="ManagePropertyContainer">
          <div
            style={{ backgroundColor: "#90EE90" }}
            className="ManagePropertyIconCircle"
            onClick={onEditClick}
          >
            <AiFillEdit />
          </div>

          <div
            style={{ backgroundColor: "#FFCCCB" }}
            className="ManagePropertyIconCircle"
            onClick={onDeleteClick}
          >
            <AiFillDelete />
          </div>
        </div>
      )}
      {isUserDashboard && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "70%",
            }}
            onClick={onClickBuy}
          >
            <Button style={{ height: 40, width: "70%", fontSize: "0.9rem" }}>
              Buy Now
            </Button>
          </div>
          <div style={{ width: "30%" }} className="ManagePropertyContainer">
            <div
              style={{ backgroundColor: "#FFCCCB" }}
              className="ManagePropertyIconCircle"
              onClick={onDeleteClick}
            >
              <AiFillDelete />
            </div>
          </div>
        </div>
      )}
    </Card>
  );
}
