import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Carousel,
  Card,
} from "react-bootstrap";
import { CustomModal } from "../../components/customModal/CustomModal";
import _, { isEmpty } from "lodash";
import { AiFillMinusCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import HorizontalBar from "../../components/horizontalBar/HorizontalBar";
import NavBar from "../../components/navBar/NavBar";
import Footer from "../../components/footer/Footer";
import {
  createPost,
  deletePropery,
  imageURL,
  updateProperty,
} from "../../api/apis";
import { useDispatch, useSelector } from "react-redux";
import { getAllPostAction } from "../../redux/actions/propertyAction";
import { productType } from "../../shared/constants";
import AgentPropertyCard from "../../components/agentPropertyCard/AgentPropertyCard";
import countries from "../../shared/countries.json";
import LoadingOverlay from "react-loading-overlay";

function AdminProperties() {
  const [modalShow, setModalShow] = useState(false);
  const [selectedImages, setselectedImages] = useState([]);
  const [selectedUpdateImages, setSelectedUpdateImages] = useState([]);
  const [isOpenViewModal, setIsOpenViewModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState();
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [formData, setFormData] = useState();
  const [deleteProperty, setDeleteProperty] = useState();
  const [country, setCountry] = useState("");
  const [loading, setloading] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.propertyReducer);

  useEffect(() => {
    dispatch(
      getAllPostAction({
        productType: "",
        country: "",
        city: "",
        startPrice: "",
        endPrice: "",
        userId: 1,
      })
    );
  }, []);

  const openViewModal = (data) => {
    setSelectedProperty(data);
    setIsOpenViewModal((prev) => !prev);
  };

  const hideViewModal = () => {
    setIsOpenViewModal(false);
  };

  const handleFileChange = (e) => {
    let files = [];
    Array.from(e.target.files).forEach((file) => {
      files.push(...selectedImages, file);
    });
    setselectedImages(files);
  };

  const handleUpdateFileChange = (e) => {
    let files = [];
    Array.from(e.target.files).forEach((file) => {
      files.push(...selectedUpdateImages, file);
    });
    setSelectedUpdateImages(files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      const formData = new FormData();
      const userId = JSON.parse(localStorage.getItem("user")).id;

      const payload = {
        price: e.target[1].value,
        productName: e.target[0].value,
        productType: e.target[2].value,
        country: e.target[3].value,
        city: e.target[4].value,
        description: e.target[5].value,
        userId: userId,
      };

      formData.append("product", JSON.stringify(payload));

      for (let i = 0; i < selectedImages.length; i++) {
        formData.append("files", selectedImages[i]);
      }
      try {
        setModalShow(false);
        setloading(true);
        const data = await createPost(formData);
        dispatch(
          getAllPostAction({
            productType: "",
            country: "",
            city: "",
            startPrice: "",
            endPrice: "",
            userId: 1,
          })
        );
        setValidated(false);
        setselectedImages([]);
        setloading(false);
      } catch (error) {
        setloading(false);
      }
    }
  };

  const urlToObject = async (url, name) => {
    return new Promise((resolve, reject) => {
      fetch(url).then(async (response) => {
        const blob = await response.blob();
        const file = new File([blob], name, { type: blob.type });
        resolve(file);
      });
      // here image is url/location of image
    });
  };

  const handleRemove = (image) => {
    let prevData = [...selectedImages];
    _.remove(prevData, (prev) => prev.name === image.name);
    setselectedImages(prevData);
  };

  const handleRemoveUpdateImage = (image) => {
    let prevData = [...selectedUpdateImages];
    _.remove(prevData, (prev) => prev.name === image.name);
    setSelectedUpdateImages(prevData);
  };

  const handleOpenUpdateModal = async (property) => {
    setloading(true);
    const dataList = [];
    for (let i = 0; i < property?.files.length; i++) {
      const res = await urlToObject(
        imageURL + property?.files[i].name,
        property?.files[i].name
      );
      dataList.push(res);
    }
    setSelectedUpdateImages(dataList);
    setFormData(property);
    setloading(false);
    setUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    setUpdateModal(false);
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteModal(true);
    setDeleteProperty(id);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (
      e.target[0].value === "" ||
      e.target[1].value === "" ||
      e.target[2].value === "" ||
      e.target[3].value === "" ||
      e.target[4].value === "" ||
      e.target[5].name === "" ||
      isEmpty(selectedUpdateImages)
    ) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      const formDataI = new FormData();
      const payload = {
        price: e.target[1].value,
        productName: e.target[0].value,
        productType: e.target[2].value,
        country: e.target[3].value,
        city: e.target[4].value,
        description: e.target[5].value,
        userId: formData?.userId,
        id: formData?.id,
      };

      formDataI.append("product", JSON.stringify(payload));

      for (let i = 0; i < selectedUpdateImages.length; i++) {
        formDataI.append("files", selectedUpdateImages[i]);
      }
      try {
        handleCloseUpdateModal();
        setloading(true);
        const data = await updateProperty(formDataI);
        dispatch(
          getAllPostAction({
            productType: "",
            country: "",
            city: "",
            startPrice: "",
            endPrice: "",
            userId: 1,
          })
        );
        setFormData({});
        setValidated(true);
        setSelectedUpdateImages([]);
        setloading(false);
      } catch (error) {
        setloading(false);
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      setloading(true);
      const data = await deletePropery(id);
      handleCloseDeleteModal();
      dispatch(
        getAllPostAction({
          productType: "",
          country: "",
          city: "",
          startPrice: "",
          endPrice: "",
          userId: 1,
        })
      );
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };

  return (
    <section>
      <LoadingOverlay active={loading} spinner text="Loading your content...">
        <NavBar />
        <Container fluid className="dashboard-section" id="home">
          <Row className="dashboard-header-row">
            <Col md={8} className="home-header">
              <span
                style={{
                  paddingBottom: 15,
                  fontSize: "100px",
                  textAlign: "center",
                  color: "#fff",
                }}
                className="heading"
              >
                {t("shareestateproperties")}
              </span>
            </Col>
          </Row>
        </Container>
        <Container
          style={{ minHeight: window.innerHeight - 100 }}
          fluid
          className="home-about-section"
          id="about"
        >
          <Row
            className="g-4"
            style={{ justifyContent: "center", paddingBottom: "10px" }}
          >
            {!_.isEmpty(data) ? (
              data?.map((property) => (
                <Col md={3} xs={12} className="property-card" key={property}>
                  <AgentPropertyCard
                    images={property?.files}
                    title={property.productName}
                    price={property.price}
                    country={property.country}
                    city={property.city}
                    onClick={() => openViewModal(property)}
                    type={property.productType}
                    onEditClick={() => handleOpenUpdateModal(property)}
                    onDeleteClick={() => handleOpenDeleteModal(property.id)}
                    isSold={property?.percentage === 0}
                  />
                </Col>
              ))
            ) : (
              <div style={{ marginTop: "5rem" }}>
                <img
                  alt="not-data"
                  src={require("../../assets/images/no-data.png")}
                  style={{ height: "10rem", width: "10rem" }}
                />

                <blockquote className="blockquote mb-0">
                  <p style={{ textAlign: "center", color: "#000" }}>
                    No Properties Available
                  </p>
                </blockquote>
              </div>
            )}
          </Row>
        </Container>

        <CustomModal
          title={t("newproperty")}
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row md={3} sm={1} xs={1} className="mb-3">
              <Form.Group
                as={Col}
                className="mb-sm-2  mb-xs-3 mb-3"
                controlId="formGridEmail"
              >
                <Form.Label>{t("title")}</Form.Label>
                <Form.Control required placeholder={t("titleplaceholder")} />
                <Form.Control.Feedback type="invalid">
                  {t("titleError")}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>{t("propertyamount")}</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder={t("amountplaceholder")}
                />
                <Form.Control.Feedback type="invalid">
                  {t("amountError")}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>{t("productType")}</Form.Label>
                <Form.Select required defaultValue="Select Product Type">
                  <option value={productType.SELL}>Sell</option>
                  <option value={productType.RENT}>Rent</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {t("propertyTypeError")}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>Country</Form.Label>
                <Form.Select
                  onChange={(event) => {
                    setCountry(event.target.value);
                  }}
                  required
                  defaultValue="Select Country"
                >
                  <option>{t("selectcountry")}</option>
                  {Object.keys(countries).map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {t("countryError")}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>City</Form.Label>
                <Form.Select required defaultValue="Select City">
                  <option>{t("selectcity")}</option>
                  {country !== "" &&
                    countries[country].map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {t("cityError")}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>{t("description")}</Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  placeholder={t("descriptionplaceholder")}
                />
                <Form.Control.Feedback type="invalid">
                  {t("descError")}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Form.Group controlId="profileImage">
              <Form.Label>{t("propertypicture")}</Form.Label>
              <Row className="mb-3 SelectedImagesRow ">
                {!_.isEmpty(selectedImages) &&
                  selectedImages.map((image) => {
                    return (
                      <Col xs lg="2">
                        <div className="SelectedImagesContainer">
                          <img
                            alt="profile"
                            src={URL.createObjectURL(image)}
                            style={{
                              height: "100%",
                              width: "100%",
                            }}
                          />
                          <AiFillMinusCircle
                            onClick={() => handleRemove(image)}
                            color="red"
                            className="ClearImage"
                          />
                        </div>
                      </Col>
                    );
                  })}
                {selectedImages.length >= 3 ? null : (
                  <Col xs lg="2">
                    <label for="file-upload" className="CustomFileUpload">
                      <img
                        alt="profile"
                        src={require("../../assets/images/upload.png")}
                        style={{
                          height: "40%",
                          width: "40%",
                        }}
                      />
                      <span className="AddPhotoText">{t("addphoto")}</span>
                      <Form.Control
                        required
                        multiple
                        onChange={handleFileChange}
                        id="file-upload"
                        type="file"
                        name="profileImage"
                        style={{ display: "none" }}
                      />
                    </label>
                  </Col>
                )}
              </Row>
            </Form.Group>

            <div className="d-flex justify-content-end">
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </CustomModal>

        <CustomModal
          title={t("showproperty")}
          size="xl"
          show={isOpenViewModal}
          onHide={hideViewModal}
        >
          <Row>
            <Col>
              <Carousel>
                {selectedProperty?.files.map((image) => {
                  return (
                    <Carousel.Item>
                      <img
                        className="d-block w-100 mx-auto"
                        src={
                          !isEmpty(selectedProperty?.files)
                            ? imageURL + image.name
                            : require("../../assets/images/no-image.jpg")
                        }
                        alt="First slide"
                      />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </Col>
            <Col
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              sm={12}
              md={5}
            >
              <Row className="mb-sm-4 mb-xs-4">
                <Card.Text className="PropertyTitleModalView">
                  {selectedProperty?.productName}
                </Card.Text>
                <Card.Text>
                  <i className="fa fa-map-marker GreenColor"></i>{" "}
                  {selectedProperty?.city}, {selectedProperty?.country}
                </Card.Text>
                <Card.Text className="PropertyPrice">
                  {t("dollar")}
                  {selectedProperty?.price}
                </Card.Text>
              </Row>
              {selectedProperty?.percentage !== 0 ? (
                <Row>
                  <div className="PercentageContainer">
                    <Card.Text className="AvailablePercentage">
                      {selectedProperty?.percentage} % Available
                    </Card.Text>
                    <Card.Text className="AvailablePercentage">
                      {100 - selectedProperty?.percentage} % Sold
                    </Card.Text>
                  </div>
                </Row>
              ) : (
                <Button className="SoldButton" disabled={true}>
                  {t("sold")}
                </Button>
              )}
            </Col>
          </Row>

          <HorizontalBar />

          <Row>
            <Col>
              <Card.Text className="PropertyDetailsText">
                {t("propertydetails")}
              </Card.Text>
              <Card.Text>{selectedProperty?.description}</Card.Text>
            </Col>
          </Row>
        </CustomModal>

        <CustomModal
          title={t("updateproperty")}
          show={updateModal}
          onHide={handleCloseUpdateModal}
        >
          <Form noValidate validated={validated} onSubmit={handleUpdate}>
            <Row md={3} sm={1} xs={1} className="mb-3">
              <Form.Group
                as={Col}
                className="mb-sm-2  mb-xs-3 mb-3"
                controlId="formGridEmail"
              >
                <Form.Label>{t("title")}</Form.Label>
                <Form.Control
                  name="productName"
                  value={formData?.productName}
                  required
                  placeholder={t("titleplaceholder")}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {t("titleError")}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>{t("propertyamount")}</Form.Label>
                <Form.Control
                  name="price"
                  value={formData?.price}
                  required
                  type="number"
                  placeholder={t("amountplaceholder")}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {t("amountError")}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>{t("productType")}</Form.Label>
                <Form.Select
                  name="productType"
                  value={formData?.productType}
                  required
                  defaultValue="Select Product Type"
                  onChange={handleChange}
                >
                  <option value={productType.SELL}>Sell</option>
                  <option value={productType.RENT}>Rent</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {t("propertyTypeError")}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>Country</Form.Label>
                <Form.Select
                  name="country"
                  value={formData?.country}
                  required
                  defaultValue="Select Country"
                  onChange={handleChange}
                >
                  <option>{t("selectcountry")}</option>
                  {Object.keys(countries).map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {t("countryError")}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>City</Form.Label>
                <Form.Select
                  value={formData?.city}
                  required
                  defaultValue="Select City"
                  name="city"
                  onChange={handleChange}
                >
                  <option>{t("selectcity")}</option>
                  {country !== "" &&
                    countries[country].map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {t("cityError")}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>{t("description")}</Form.Label>
                <Form.Control
                  name="description"
                  value={formData?.description}
                  required
                  as="textarea"
                  placeholder={t("descriptionplaceholder")}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {t("descError")}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Form.Group controlId="profileImage">
              <Form.Label>{t("propertypicture")}</Form.Label>
              <Row className="mb-3 SelectedImagesRow ">
                {!_.isEmpty(selectedUpdateImages) &&
                  selectedUpdateImages.map((image) => {
                    return (
                      <Col xs lg="2">
                        <div className="SelectedImagesContainer">
                          <img
                            alt="profile"
                            src={URL.createObjectURL(image)}
                            style={{
                              height: "100%",
                              width: "100%",
                            }}
                          />
                          <AiFillMinusCircle
                            onClick={() => handleRemoveUpdateImage(image)}
                            color="red"
                            className="ClearImage"
                          />
                        </div>
                      </Col>
                    );
                  })}
                {selectedImages.length >= 3 ? null : (
                  <Col xs lg="2">
                    <label for="file-upload" className="CustomFileUpload">
                      <img
                        alt="profile"
                        src={require("../../assets/images/upload.png")}
                        style={{
                          height: "40%",
                          width: "40%",
                        }}
                      />
                      <span className="AddPhotoText">{t("addphoto")}</span>
                      <Form.Control
                        required
                        multiple
                        onChange={handleUpdateFileChange}
                        id="file-upload"
                        type="file"
                        name="profileImage"
                        style={{ display: "none" }}
                        // value={selectedUpdateImages[0]?.name}
                      />
                    </label>
                  </Col>
                )}
              </Row>
            </Form.Group>

            <div className="d-flex justify-content-end">
              <Button variant="primary" type="submit">
                {t("update")}
              </Button>
            </div>
          </Form>
        </CustomModal>

        <CustomModal
          size="md"
          title={t("deleteproperty")}
          show={deleteModal}
          onHide={handleCloseDeleteModal}
          footer={true}
          handleDelete={() => handleDelete(deleteProperty)}
        >
          <div>
            <Card.Text>Are you want to delete this property?</Card.Text>
          </div>
        </CustomModal>

        <Footer />
      </LoadingOverlay>
    </section>
  );
}

export default AdminProperties;
