/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./Login.css";
import Switch from "react-switch";
import history from "../../shared/history";
import { login, register, resetPassword } from "../../api/apis";
import jwtDecode from "jwt-decode";
import FacebookLogin from "react-facebook-login";
import LoadingOverlay from "react-loading-overlay";
import toast, { Toaster } from "react-hot-toast";
import { GoogleLogin } from "@react-oauth/google";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { FaFacebookF } from "react-icons/fa";
// import { ToastContainer, toast as Tostify } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function LoginTwo() {
  const [isSignUpMode, setIsSignUpMode] = useState(false);
  const [checked, setchecked] = useState(false);
  const [loading, setloading] = useState(false);
  const [isShowPassword, setisShowPassword] = useState(false);
  const [show, setshow] = useState(false);

  const handleSignUpClick = () => {
    setIsSignUpMode(true);
  };
  const handleSignInClick = () => {
    setIsSignUpMode(false);
  };
  const handleChange = (checked) => {
    setchecked(checked);
  };

  const handleSignup = async (e) => {
    setloading(true);
    e.preventDefault();
    const form = e.currentTarget;

    const payload = {
      firstName: e.target[0].value,
      lastName: e.target[1].value,
      userName: "",
      phoneNumber: e.target[2].value,
      email: e.target[3].value,
      password: e.target[4].value,
      status: e.target[5].checked ? false : true,
      roleId: e.target[5].checked ? 2 : 3,
      autoSignUp: false,
    };
    register(payload)
      .then((res) => {
        if (e.target[5].checked) {
          toast.success(
            "You have registered the account. Our team confirm your account and let you know through your mail"
          );
          form.reset();
        } else {
          localStorage.setItem("user", JSON.stringify(res.User));
          toast.success("Login successfully");
          history.push("/home");
          window.location.reload();
        }

        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        toast.error(err.data?.validationFailures[0]?.message);
      });
  };

  const handleAutoSignup = async (values) => {
    setloading(true);
    try {
      const payload = {
        email: values?.email,
        userName: values.email,
        status: true,
        roleId: checked === true ? 2 : 3,
        autoSignUp: true,
      };
      const data = await register(payload);
      localStorage.setItem("user", JSON.stringify(data.User));
      toast.success("Login successfully");
      history.push("/home");
      window.location.reload();
      setloading(false);
    } catch (error) {
      toast.error(error.data?.validationFailures[0]?.message);
      setloading(false);
    }
  };

  const loginwithFacebookFunction = async (username) => {
    setloading(true);
    try {
      const payload = {
        email: null,
        userName: username,
        status: true,
        roleId: checked === true ? 2 : 3,
        autoSignUp: true,
        password: null,
      };
      const data = await register(JSON.stringify(payload));
      localStorage.setItem("user", JSON.stringify(data.User));
      toast.success("Login successfully");
      history.push("/home");
      window.location.reload();
      setloading(false);
    } catch (error) {
      toast.error(error.data?.validationFailures[0]?.message);
      setloading(false);
    }
  };

  const loginUser = async (e) => {
    e.preventDefault();
    const payload = {
      email: e.target[0].value,
      password: e.target[1].value,
    };
    try {
      const data = await login(payload);
      if (data) {
        localStorage.setItem("user", JSON.stringify(data.User));
        toast.success("Login successfully");
        history.push("/home");
        window.location.reload();
      }
    } catch (error) {
      toast.error(error.data?.validationFailures[0]?.message);
    }
  };

  const responseGoogle = (response) => {
    const credential = jwtDecode(response.credential);
    if (response.credential) handleAutoSignup(credential);
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: responseGoogle,
    });

    // eslint-disable-next-line no-undef
    google.accounts.id.renderButton(document.getElementById("google-sign-in"), {
      // theme: "outline",
      size: "large",
      longtitle: true,
      theme: "dark",
    });
  }, []);

  const responseFacebook = (response) => {
    loginwithFacebookFunction(response?.name);
  };

  const handlePressEye = () => {
    setisShowPassword(!isShowPassword);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const mail = e.target[0].value;
    try {
      await resetPassword(mail);
      toast.success("Password resetting success! check your mail inbox");
      setshow(false);
    } catch (error) {
      console.log({ error });
      toast.error(error?.data?.validationFailures[0]?.message);
    }
  };

  return (
    <LoadingOverlay active={loading} spinner text="Loading your content...">
      <div
        className={`login-screen-container ${
          isSignUpMode ? "sign-up-mode" : ""
        }`}
      >
        <Toaster toastOptions={{ duration: 5000 }} />
        <div className="forms-container">
          <div className="signin-signup">
            <form onSubmit={loginUser} className="sign-form sign-in-form">
              <h2 className="title">Sign in</h2>
              <div className="input-field">
                <i className="fas fa-user"></i>
                <input required type="text" placeholder="Email" />
              </div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.7rem",
                  paddingRight: "1.7rem",
                }}
                className="input-field"
              >
                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <i className="fas fa-lock"></i>
                  <input
                    id="password-input"
                    required
                    type={isShowPassword ? "text" : "password"}
                    placeholder="Password"
                  />
                </div>
                <div>
                  <i
                    onClick={handlePressEye}
                    class={isShowPassword ? "fa fa-eye-slash" : "fas fa-eye"}
                    id="toggle-password"
                  ></i>
                </div>
              </div>
              <button
                title="Login"
                type="submit"
                value="Login"
                className="btn solid"
              >
                Login
              </button>
              <p className="social-text">Or Sign in with social platforms</p>
              <div className="social-media">
                {/* <div id="google-sign-in" /> */}
                <GoogleLogin
                  type="icon"
                  shape="circle"
                  onSuccess={(credentialResponse) => {
                    responseGoogle(credentialResponse);
                  }}
                  onError={() => {
                    toast.error("Login failed!");
                  }}
                />
                <FacebookLogin
                  containerStyle={{
                    borderRadius: "50%",
                    backgroundColor: "#fff",
                    border: "0.5px solid #E0E2E5",
                    height: "38px",
                    width: "38px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  tag={() => (
                    <FaFacebookF
                      onClick={responseFacebook}
                      style={{ fontSize: "20px" }}
                      color="#4C69BA"
                    />
                  )}
                  appId="1338503270028144"
                  autoLoad={false}
                  fields="name,email,picture"
                  scope="public_profile,email,user_friends"
                  callback={responseFacebook}
                  cssClass="my-facebook-button-class"
                />
              </div>
              <div
                style={{
                  color: "blue",
                  fontSize: "14px",
                  marginTop: "1rem",
                  cursor: "pointer",
                }}
                onClick={() => setshow(true)}
              >
                Forgot Password ?
              </div>
            </form>
            <form onSubmit={handleSignup} className="sign-form sign-up-form">
              {/* <h2 className="title">Sign up</h2> */}

              <div className="input-field">
                <i className="fas fa-user"></i>
                <input required type="text" placeholder="Firstname" />
              </div>

              <div className="input-field">
                <i className="fas fa-user"></i>
                <input required type="text" placeholder="Lastname" />
              </div>

              <div className="input-field">
                <i className="fas fa-phone"></i>
                <input required type="text" placeholder="Phone number" />
              </div>

              <div className="input-field">
                <i className="fas fa-envelope"></i>
                <input required type="email" placeholder="Email" />
              </div>

              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.7rem",
                  paddingRight: "1.7rem",
                }}
                className="input-field"
              >
                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <i className="fas fa-lock"></i>
                  <input
                    required
                    type={isShowPassword ? "text" : "password"}
                    placeholder="Password"
                  />
                </div>
                <div>
                  <i
                    onClick={handlePressEye}
                    class={isShowPassword ? "fa fa-eye-slash" : "fas fa-eye"}
                    id="toggle-password"
                  ></i>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "1rem",
                }}
              >
                <span>Do you want become a Agent ? </span>
                <Switch
                  onChange={handleChange}
                  checked={checked}
                  onColor="#149777"
                />
              </div>

              <button
                // onClick={handleSignup}
                type="submit"
                className="btn"
                value="Sign up"
              >
                Sign up
              </button>
              {/* <p className="social-text">Or Sign up with social platforms</p> */}
              {!checked ? (
                <div className="social-media mt-2">
                  {/* <div id="google-sign-in" /> */}
                  {/* <a className="social-icon">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a onClick={handleGoogle} className="social-icon">
                    <i className="fab fa-google"></i>
                  </a> */}
                  <GoogleLogin
                    type="icon"
                    shape="circle"
                    onSuccess={(credentialResponse) => {
                      responseGoogle(credentialResponse);
                    }}
                    onError={() => {
                      toast.error("Login failed!");
                    }}
                  />

                  <FacebookLogin
                    containerStyle={{
                      borderRadius: "50%",
                      backgroundColor: "#fff",
                      border: "0.5px solid #E0E2E5",
                      height: "38px",
                      width: "38px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    tag={() => (
                      <FaFacebookF
                        onClick={responseFacebook}
                        style={{ fontSize: "20px" }}
                        color="#4C69BA"
                      />
                    )}
                    appId="1338503270028144"
                    autoLoad={false}
                    fields="name,email,picture"
                    scope="public_profile,email,user_friends"
                    // autoLoad={true}
                    callback={responseFacebook}
                    cssClass="my-facebook-button-class"
                    icon="fa-facebook"
                  />
                </div>
              ) : (
                <blockquote className="blockquote mb-0">
                  <p
                    style={{
                      textAlign: "center",
                      color: "red",
                      fontSize: "0.6em",
                    }}
                  >
                    Sorry! If You're agent you cannot register with social
                    media.
                  </p>
                </blockquote>
              )}
            </form>
          </div>
        </div>

        <div className="panels-container">
          <div className="panel left-panel">
            <div className="content">
              <h3>Login with Share Estate</h3>
              <p>We Specialise in All Aspects of Property.</p>
              <button
                onClick={handleSignUpClick}
                className="btn transparent"
                id="sign-up-btn"
              >
                Sign up
              </button>
            </div>
            <img
              src={require("../../assets/images/login.png")}
              className="image"
              alt=""
            />
          </div>
          <div className="panel right-panel">
            <div className="content">
              <h3>Signup with Share Estate</h3>
              <p>We Specialise in All Aspects of Property.</p>
              <button
                onClick={handleSignInClick}
                className="btn transparent"
                id="sign-in-btn"
              >
                Sign in
              </button>
            </div>
            <img
              src={require("../../assets/images/login.png")}
              className="image"
              alt=""
            />
          </div>
        </div>
      </div>
      <Modal show={show} onHide={() => setshow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Reset
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </LoadingOverlay>
  );
}
