import { getPosts, getPostsByUser } from "../../api/apis";

export const getAllPostAction = (body) => async (dispatch) => {
  dispatch({ type: "GET_PROPERTY_LOADING" });
  try {
    const data = await getPosts(body);
    dispatch({ type: "GET_PROPERTY_SUCCESS", data: data });
  } catch (error) {
    dispatch({ type: "GET_PROPERTY_FAIL" });
  }
};

export const getAllPostByUserAction = (email) => async (dispatch) => {
  dispatch({ type: "GET_PROPERTY_BY_USER_LOADING" });
  try {
    const data = await getPostsByUser(email);
    dispatch({ type: "GET_PROPERTY_BY_USER_SUCCESS", data: data });
  } catch (error) {
    dispatch({ type: "GET_PROPERTY_BY_USER_FAIL" });
  }
};
