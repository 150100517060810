import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaHandPointRight } from "react-icons/fa";
import Footer from "../../components/footer/Footer";
import NavBar from "../../components/navBar/NavBar";

export default function About() {
  const { t } = useTranslation();
  return (
    <section>
      <NavBar />
      <Container fluid className="home-section" id="home">
        <Row className="home-header-row">
          <Col md={8} className="home-header">
            <span
              style={{
                paddingBottom: 15,
                fontSize: "100px",
                textAlign: "center",
                color: "#fff",
              }}
              className="heading"
            >
              {t("aboutus")}
            </span>
          </Col>
        </Row>
      </Container>
      <Container
        style={{ minHeight: window.innerHeight - 100 }}
        fluid
        className="home-about-section"
      >
        <Container>
          <Row style={{ justifyContent: "center", padding: "10px" }}>
            <Col
              md={7}
              style={{
                justifyContent: "center",
                paddingTop: "30px",
                paddingBottom: "50px",
              }}
            >
              <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
                {t("knowwho")} <strong className="purple">{t("weare")}</strong>
              </h1>
              <Card className="quote-card-view">
                <Card.Body>
                  <blockquote className="blockquote mb-0">
                    <p style={{ textAlign: "justify", color: "#000" }}>
                      <span className="purple">{t("aboutheader")}</span>
                      <br />
                      {t("aboutdesc")}
                      <br />
                      <br />
                      {t("ourservice")}
                    </p>
                    <ul>
                      <li className="about-activity">
                        <FaHandPointRight /> {t("sellproperty")}
                      </li>
                      <li className="about-activity">
                        <FaHandPointRight /> {t("buyproperty")}
                      </li>
                      <li className="about-activity">
                        <FaHandPointRight /> {t("rentproperty")}
                      </li>
                    </ul>
                  </blockquote>
                </Card.Body>
              </Card>
            </Col>
            <Col
              md={5}
              style={{ paddingTop: "120px", paddingBottom: "50px" }}
              className="about-img"
            >
              <img
                src={require("../../assets/images/about.png")}
                alt="about"
                className="img-fluid"
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Footer />
    </section>
  );
}
