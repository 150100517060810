import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Carousel,
  Card,
  Table,
  FormControl,
} from "react-bootstrap";
import { CustomModal } from "../../components/customModal/CustomModal";
import _, { isEmpty } from "lodash";
import { AiFillMinusCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import HorizontalBar from "../../components/horizontalBar/HorizontalBar";
import NavBar from "../../components/navBar/NavBar";
import Footer from "../../components/footer/Footer";
import {
  buyProperty,
  createPost,
  deleteFavourite,
  deleteFile,
  deletePropery,
  getApprovedProperties,
  getFavouritesById,
  getpendingRequest,
  imageURL,
  pdfURL,
  updateProperty,
  updateRequestStatus,
  uploadDocuments,
} from "../../api/apis";
import { useDispatch, useSelector } from "react-redux";
import { getAllPostByUserAction } from "../../redux/actions/propertyAction";
import { productType } from "../../shared/constants";
import AgentPropertyCard from "../../components/agentPropertyCard/AgentPropertyCard";
import countries from "../../shared/countries.json";
import LoadingOverlay from "react-loading-overlay";
import { toast, Toaster } from "react-hot-toast";
import PropertyCard from "../../components/propertyCard/PropertyCard";
import GoogleDocsViewer from "react-google-docs-viewer";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import useGeocoder from "../../hooks/useGeocoder";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Map from "../../components/map/Map";
import MapBox from "../../components/map/Map";

const PdfOrWordViewer = ({ url }) => {
  const { t } = useTranslation();
  const extension = url.match(/\.(pdf|docx)$/)[1];
  if (extension === "pdf") {
    return <GoogleDocsViewer width="600px" height="600px" fileUrl={url} />;
  } else if (extension === "docx") {
    return (
      <Card
        style={{
          width: "15rem",
        }}
      >
        <Card.Img
          variant="top"
          src="https://play-lh.googleusercontent.com/FOIfyL9U8PcmbnfDjcpdUWNZcWLMY24p5SrGERmRr_K-yeqv5ZzjJ81B4ZfmV07kg_Yu"
        />
        <Card.Body style={{ textAlign: "center" }}>
          <Card.Text>{t("documentedfile")}</Card.Text>
          <Button href={url} variant="primary">
            {t("download")}
          </Button>
        </Card.Body>
      </Card>
    );
  } else {
    return <p>Unsupported file type</p>;
  }
};

function Dashboard() {
  const [modalShow, setModalShow] = useState(false);
  const [selectedImages, setselectedImages] = useState([]);
  const [selectedUpdateImages, setSelectedUpdateImages] = useState([]);
  const [isOpenViewModal, setIsOpenViewModal] = useState(false);
  const [isOpenManageRequest, setisOpenManageRequest] = useState(false);
  const [validated, setValidated] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState();
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteFavModal, setDeleteFavModal] = useState(false);
  const [deleteFileShow, setdeleteFileShow] = useState(false);
  const [formData, setFormData] = useState();
  const [deleteProperty, setDeleteProperty] = useState();
  const [deleteFavProperty, setDeleteFavProperty] = useState();
  const [country, setCountry] = useState("");
  const [loading, setloading] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [propertyType, setPropertyType] = useState("YOURPROPERTY");
  const [pendingData, setPendingData] = useState([]);
  const [approvedData, setapprovedData] = useState([]);
  const [selectedPendingProperty, setSelectedPendingProperty] = useState();
  const [coords, setCoords] = React.useState({});
  const [favouritesData, setFavouriteData] = useState([]);
  const [isPublic, setIsPublic] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [counter, setCounter] = useState(5);
  const [isError, setIsError] = useState(false);
  const [buyOptionCountry, setBuyOptionCountry] = useState("");
  const [confirmDisable, setconfirmDisable] = useState(false);

  const handleRadioChange = (e) => {
    setIsPublic(e.target.value === "public");
  };

  const address = useGeocoder("");

  const handleUploadChange = (event) => {
    setUploadFile(event.target.files[0]);
  };

  const handleButtonClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("productId", selectedProperty?.id);
      formData.append("files", uploadFile);
      await uploadDocuments(formData);
      toast.success("File uploaded successfully");
      setIsOpenViewModal(false);
      setUploadFile(null);
      const userId = JSON.parse(localStorage.getItem("user")).id;
      dispatch(getAllPostByUserAction(userId));
    } catch (error) {
      toast.error("Error while uploading file");
    }
  };

  const handleDeleteFile = async () => {
    try {
      await deleteFile(selectedProperty.id);
      toast.success("File deleted successfully");
      setdeleteFileShow(false);
      setIsOpenViewModal(false);
      const userId = JSON.parse(localStorage.getItem("user")).id;
      dispatch(getAllPostByUserAction(userId));
    } catch (error) {
      toast.error("Error while deleting file");
    }
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.agentPropertyReducer);

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("user")).id;
    dispatch(getAllPostByUserAction(userId));
  }, []);

  const listAllPendingRequest = async () => {
    try {
      const userId = JSON.parse(localStorage.getItem("user")).id;
      const response = await getpendingRequest(userId);
      setPendingData(response);
    } catch (error) {}
  };

  const listAllApprovedProperties = async () => {
    try {
      const userId = JSON.parse(localStorage.getItem("user")).id;
      const response = await getApprovedProperties(userId);
      setapprovedData(response);
    } catch (error) {}
  };

  const getAllFavourites = () => {
    const userId = JSON.parse(localStorage.getItem("user")).id;
    getFavouritesById(userId)
      .then((res) => {
        setFavouriteData(res);
      })
      .catch((err) => {});
  };

  const openManageRequestModal = async (data) => {
    setSelectedPendingProperty(data);
    setisOpenManageRequest((prev) => !prev);
  };

  const openViewModal = async (data) => {
    console.log({ data });
    setSelectedProperty(data);
    setIsOpenViewModal((prev) => !prev);
  };

  const hideViewModal = () => {
    setIsOpenViewModal(false);
  };

  const handleFileChange = (e) => {
    let files = [];
    Array.from(e.target.files).forEach((file) => {
      files.push(...selectedImages, file);
    });
    setselectedImages(files);
  };

  const handleUpdateFileChange = (e) => {
    let files = [];
    Array.from(e.target.files).forEach((file) => {
      files.push(...selectedUpdateImages, file);
    });
    setSelectedUpdateImages(files);
  };

  const handleSelect = (selected) => {
    console.log({ selected });
    const coords = selected[0]?.geometry?.coordinates;
    setCoords({
      lat: coords[0],
      lng: coords[1],
      address: selected[0]?.place_name,
    });
  };

  const handleBuy = async (e) => {
    e.preventDefault();
    if (/^\d+$/.test(counter) && counter >= 5 && counter <= 100) {
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        setValidated(true);
      } else {
        const userId = JSON.parse(localStorage.getItem("user")).id;
        const user = JSON.parse(localStorage.getItem("user"));
        const payload = {
          percentage: counter,
          productDetailsId: selectedProperty?.productDetails?.id,
          buyerId: userId,
          agentId: selectedProperty.userId,
          firstname: user?.firstName,
          lastname: user?.lastName,
          email: user?.email,
          phoneNumber: user?.phoneNumber,
          question: e.target[0]?.value,
          address: selectedProperty?.productDetails?.address,
        };
        handleBuyProperty(payload);
      }
    } else {
      setIsError(true);
      document
        .querySelector("#buyButtonQuery")
        .scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSubmit = async (e) => {
    console.log({ e });
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      const formData = new FormData();
      const userId = JSON.parse(localStorage.getItem("user")).id;

      const payload = {
        price: e.target[1].value,
        productName: e.target[0].value,
        productType: e.target[2].value,

        roomsAmount: e.target[3].value,
        squareMeters: e.target[4].value,
        departmentFloor: e.target[5].value,
        totalFloor: e.target[6].value,
        entryDate: e.target[7].value,

        country: e.target[8].value,
        city: e.target[9].value,
        description: e.target[12].value,
        elevator: e.target[13].checked,
        ac: e.target[14].checked,
        balcony: e.target[15].checked,
        parking: e.target[16].checked,
        bar: e.target[17].checked,
        warehouse: e.target[18].checked,
        mmd: e.target[19].checked,
        pandora: e.target[20].checked,
        disabled: e.target[21].checked,
        latitude: coords?.lat,
        longitude: coords?.lng,
        address: coords?.address,
        userId: userId,
      };

      formData.append("product", JSON.stringify(payload));

      for (let i = 0; i < selectedImages.length; i++) {
        formData.append("files", selectedImages[i]);
      }
      try {
        setModalShow(false);
        setloading(true);
        await createPost(formData);
        const userId = JSON.parse(localStorage.getItem("user")).id;
        dispatch(getAllPostByUserAction(userId));
        setValidated(false);
        setselectedImages([]);
        setloading(false);
      } catch (error) {
        setloading(false);
      }
    }
  };

  const urlToObject = async (url, name) => {
    return new Promise((resolve, reject) => {
      fetch(url).then(async (response) => {
        const blob = await response.blob();
        const file = new File([blob], name, { type: blob.type });
        resolve(file);
      });
      // here image is url/location of image
    });
  };

  const handleRemove = (image) => {
    let prevData = [...selectedImages];
    _.remove(prevData, (prev) => prev.name === image.name);
    setselectedImages(prevData);
  };

  const handleRemoveUpdateImage = (image) => {
    let prevData = [...selectedUpdateImages];
    _.remove(prevData, (prev) => prev.name === image.name);
    setSelectedUpdateImages(prevData);
  };

  const open = () => {
    setModalShow((prev) => !prev);
  };

  const handleOpenUpdateModal = async (property) => {
    setUpdateModal(true);
    const dataList = [];
    for (let i = 0; i < property?.files.length; i++) {
      const res = await urlToObject(
        imageURL + property?.files[i].name,
        property?.files[i].name
      );
      dataList.push(res);
    }
    setSelectedUpdateImages(dataList);

    setFormData(property);
    setIsPublic(!property?.pdfStatus);
    setCountry(property?.country);
    setCoords({
      lat: property.latitude,
      lng: property.longitude,
    });
  };

  const handleCloseUpdateModal = () => {
    setUpdateModal(false);
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteModal(true);
    setDeleteProperty(id);
  };

  const handleOpenDeleteFavModal = (id) => {
    setDeleteFavModal(true);
    setDeleteFavProperty(id);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleCloseDeleteFavModal = () => {
    setDeleteFavModal(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChecked = (e) => {
    console.log(e);
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  const handleUpdate = async (e) => {
    console.log({ e });
    e.preventDefault();
    if (
      e.target[0].value === "" ||
      e.target[1].value === "" ||
      e.target[2].value === "" ||
      e.target[3].value === "" ||
      e.target[4].value === "" ||
      e.target[7].value === "" ||
      isEmpty(selectedUpdateImages)
    ) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      const formDataI = new FormData();
      const userId = JSON.parse(localStorage.getItem("user")).id;

      console.log("e.target[19].checked", e.target[19].checked);
      console.log("e.target[18].checked", e.target[18].checked);

      const payload = {
        price: e.target[1].value,
        productName: e.target[0].value,
        productType: e.target[2].value,

        roomsAmount: e.target[3].value,
        squareMeters: e.target[4].value,
        departmentFloor: e.target[5].value,
        totalFloor: e.target[6].value,
        entryDate: e.target[7].value,

        country: e.target[8].value,
        city: e.target[9].value,
        description: e.target[12].value,
        elevator: e.target[13].checked,
        ac: e.target[14].checked,
        balcony: e.target[15].checked,
        parking: e.target[16].checked,
        bar: e.target[17].checked,
        warehouse: e.target[18].checked,
        mmd: e.target[19].checked,
        pandora: e.target[20].checked,
        disabled: e.target[21].checked,
        latitude: coords?.lat,
        longitude: coords?.lng,
        address: coords?.address,
        userId: userId,
        id: formData?.id,
        pdfStatus: !isPublic,
      };
      formDataI.append("product", JSON.stringify(payload));

      for (let i = 0; i < selectedUpdateImages.length; i++) {
        formDataI.append("files", selectedUpdateImages[i]);
      }
      const form = new FormData();
      form.append("productId", formData?.id);
      form.append("files", uploadFile);

      try {
        handleCloseUpdateModal();
        setloading(true);
        const data = await updateProperty(formDataI);
        await uploadDocuments(form);
        const userId = JSON.parse(localStorage.getItem("user")).id;

        setUploadFile(null);
        dispatch(getAllPostByUserAction(userId));
        setFormData({});
        setValidated(true);
        setSelectedUpdateImages([]);
        setloading(false);
      } catch (error) {
        setloading(false);
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      const userId = JSON.parse(localStorage.getItem("user")).id;
      await deletePropery(id);
      handleCloseDeleteModal();

      dispatch(getAllPostByUserAction(userId));
    } catch (error) {}
  };

  const handleDeleteFav = async (id) => {
    try {
      const userId = JSON.parse(localStorage.getItem("user")).id;
      const payload = {
        productDetailsId: id,
        userId: userId,
      };
      await deleteFavourite(payload);
      handleCloseDeleteFavModal();
      dispatch(getAllFavourites(userId));
    } catch (error) {}
  };

  const updateStatus = async (id) => {
    try {
      await updateRequestStatus(id);
      toast.success("Request updated successfully");
      setisOpenManageRequest(false);
      listAllPendingRequest();
    } catch (error) {
      toast.error("Error while updating request status");
    }
  };

  const openModal = (data) => {
    console.log({ data });
    setSelectedProperty(data);
    setIsVisible(true);
  };
  const hideModal = () => {
    setIsVisible(false);
  };

  const handleBuyProperty = async (data) => {
    setconfirmDisable(true);
    buyProperty(data)
      .then((res) => {
        hideModal();
        toast.success("Request sent successfully, Agent will contact you.");
        setCounter(5);
        setconfirmDisable(false);
      })
      .catch((err) => {
        setconfirmDisable(false);
      });
  };

  return (
    <section>
      <Toaster toastOptions={{ duration: 5000 }} />
      <LoadingOverlay active={loading} spinner text="Loading your content...">
        <NavBar />
        <Container fluid className="dashboard-section" id="home">
          <Row className="dashboard-header-row">
            <Col md={8} className="home-header">
              <span
                style={{
                  paddingBottom: 15,
                  fontSize: "100px",
                  textAlign: "center",
                  color: "#fff",
                }}
                className="heading"
              >
                {t("yourproperties")}
              </span>
            </Col>
            <Col md={4}>
              <Button onClick={open} variant="primary">
                {t("postnew")}
              </Button>
            </Col>
          </Row>
        </Container>
        <Container
          style={{ minHeight: window.innerHeight - 100 }}
          fluid
          className="home-about-section"
          id="about"
        >
          <Row
            style={{ borderBottom: "1px solid #ebdfdf" }}
            className="d-flex justify-content-center align-items-center mb-4 mt-2"
          >
            <Col
              className="d-flex justify-content-center align-items-center"
              md={2}
              xs={4}
              onClick={() => {
                setPropertyType("YOURPROPERTY");
                const userId = JSON.parse(localStorage.getItem("user")).id;
                dispatch(getAllPostByUserAction(userId));
              }}
            >
              <div
                className={
                  propertyType === "YOURPROPERTY"
                    ? "SelectedTypeFilterOption"
                    : "TypeFilterOption"
                }
              >
                <div>
                  <span>{t("yourproperties")}</span>
                </div>
              </div>
            </Col>

            <Col
              className="d-flex justify-content-center align-items-center"
              md={2}
              xs={4}
              onClick={() => {
                setPropertyType("REQUEST");
                listAllPendingRequest();
              }}
            >
              <div
                className={
                  propertyType === "REQUEST"
                    ? "SelectedTypeFilterOption"
                    : "TypeFilterOption"
                }
              >
                <div>
                  <span>{t("newrequest")}</span>
                </div>
              </div>
            </Col>

            <Col
              className="d-flex justify-content-center align-items-center"
              md={2}
              xs={4}
              onClick={() => {
                setPropertyType("APPROVED");
                listAllApprovedProperties();
              }}
            >
              <div
                className={
                  propertyType === "APPROVED"
                    ? "SelectedTypeFilterOption"
                    : "TypeFilterOption"
                }
              >
                <div>
                  <span>{t("approved")}</span>
                </div>
              </div>
            </Col>

            <Col
              className="d-flex justify-content-center align-items-center"
              md={2}
              xs={4}
              onClick={() => {
                setPropertyType("FAVOURITE");
                getAllFavourites();
              }}
            >
              <div
                className={
                  propertyType === "FAVOURITE"
                    ? "SelectedTypeFilterOption"
                    : "TypeFilterOption"
                }
              >
                <div>
                  <span>{t("fovouritebasketagent")}</span>
                </div>
              </div>
            </Col>
          </Row>
          {propertyType === "YOURPROPERTY" ? (
            <Row
              className="g-4"
              style={{ justifyContent: "center", paddingBottom: "10px" }}
            >
              {!_.isEmpty(data) ? (
                data?.map((property) => (
                  <Col md={3} xs={12} className="property-card" key={property}>
                    <AgentPropertyCard
                      images={property?.files}
                      title={property.productName}
                      price={property.price}
                      country={property.country}
                      city={property.city}
                      onClick={() => openViewModal(property)}
                      type={property.productType}
                      onEditClick={() => handleOpenUpdateModal(property)}
                      onDeleteClick={() => handleOpenDeleteModal(property.id)}
                      isSold={property?.percentage === 0}
                    />
                  </Col>
                ))
              ) : (
                <div style={{ marginTop: "5rem" }}>
                  <img
                    alt="not-data"
                    src={require("../../assets/images/no-data.png")}
                    style={{ height: "10rem", width: "10rem" }}
                  />

                  <blockquote className="blockquote mb-0">
                    <p style={{ textAlign: "center", color: "#000" }}>
                      No Properties Available
                    </p>
                  </blockquote>
                </div>
              )}
            </Row>
          ) : propertyType === "REQUEST" ? (
            <Row
              className="g-4"
              style={{ justifyContent: "center", paddingBottom: "10px" }}
            >
              {!_.isEmpty(pendingData) ? (
                pendingData?.map((property) => (
                  <Col md={3} xs={12} className="property-card" key={property}>
                    <PropertyCard
                      isShowLike={false}
                      images={property?.productDetails?.files}
                      title={property?.productDetails?.productName}
                      price={property?.productDetails?.price}
                      country={property?.productDetails?.country}
                      city={property?.productDetails?.city}
                      onClick={() => openManageRequestModal(property)}
                      type={property?.productDetails?.productType}
                      onEditClick={() => handleOpenUpdateModal(property)}
                      onDeleteClick={() => handleOpenDeleteModal(property.id)}
                      isSold={property?.productDetails?.percentage === 0}
                      isHaveStatus={property?.status}
                    />
                  </Col>
                ))
              ) : (
                <div style={{ marginTop: "5rem" }}>
                  <img
                    alt="not-data"
                    src={require("../../assets/images/no-data.png")}
                    style={{ height: "10rem", width: "10rem" }}
                  />

                  <blockquote className="blockquote mb-0">
                    <p style={{ textAlign: "center", color: "#000" }}>
                      No Properties Available
                    </p>
                  </blockquote>
                </div>
              )}
            </Row>
          ) : propertyType === "APPROVED" ? (
            <Row
              className="g-4"
              style={{ justifyContent: "center", paddingBottom: "10px" }}
            >
              {!_.isEmpty(approvedData) ? (
                <Table responsive striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Property</th>
                      <th>Buyer Name</th>
                      <th>Buyer Email</th>
                      <th>Phone Number</th>
                      <th>Percentage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {approvedData?.map((approved, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{approved?.productName}</td>
                          <td>
                            {approved?.userFirstName +
                              " " +
                              approved?.userLastName}
                          </td>
                          <td>{approved?.userEmail}</td>
                          <td>{approved?.phoneNumber}</td>
                          <td>{approved?.percentage}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <div style={{ marginTop: "5rem" }}>
                  <img
                    alt="not-data"
                    src={require("../../assets/images/no-data.png")}
                    style={{ height: "10rem", width: "10rem" }}
                  />

                  <blockquote className="blockquote mb-0">
                    <p style={{ textAlign: "center", color: "#000" }}>
                      No Properties Available
                    </p>
                  </blockquote>
                </div>
              )}
            </Row>
          ) : (
            <Row
              className="g-4"
              style={{ justifyContent: "center", paddingBottom: "10px" }}
            >
              {!_.isEmpty(favouritesData) ? (
                favouritesData?.map((property) => (
                  <Col md={3} xs={12} className="property-card" key={property}>
                    <AgentPropertyCard
                      isUserDashboard={true}
                      isAgent={false}
                      images={property?.productDetails?.files}
                      title={property?.productDetails.productName}
                      price={property?.productDetails.price}
                      country={property?.productDetails.country}
                      city={property?.productDetails.city}
                      onClick={() => {
                        const dataList = {
                          ...property?.productDetails,
                          percentage: property?.productDetails?.percentge,
                        };
                        openViewModal(dataList);
                      }}
                      type={property?.productDetails.productType}
                      onDeleteClick={() =>
                        handleOpenDeleteFavModal(property?.productDetails?.id)
                      }
                      isShowBuy={true}
                      onClickBuy={() => {
                        const dataList = {
                          ...property?.productDetails,
                          percentage: property?.productDetails?.percentge,
                        };
                        openModal(property);
                      }}
                    />
                  </Col>
                ))
              ) : (
                <div style={{ marginTop: "5rem" }}>
                  <img
                    alt="not-data"
                    src={require("../../assets/images/no-data.png")}
                    style={{ height: "10rem", width: "10rem" }}
                  />

                  <blockquote className="blockquote mb-0">
                    <p style={{ textAlign: "center", color: "#000" }}>
                      No Properties Available
                    </p>
                  </blockquote>
                </div>
              )}
            </Row>
          )}
        </Container>

        <CustomModal
          title={t("newproperty")}
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row md={3} sm={1} xs={1} className="mb-3">
              <Form.Group
                as={Col}
                className="mb-sm-2  mb-xs-3 mb-3"
                controlId="formGridEmail"
              >
                <Form.Label>{t("title")}</Form.Label>
                <Form.Control required placeholder={t("titleplaceholder")} />
                <Form.Control.Feedback type="invalid">
                  {t("titleError")}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>{t("propertyamount")}</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder={t("amountplaceholder")}
                />
                <Form.Control.Feedback type="invalid">
                  {t("amountError")}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>{t("productType")}</Form.Label>
                <Form.Select required defaultValue="Select Product Type">
                  <option value={productType.SELL}>Sell</option>
                  <option value={productType.RENT}>Rent</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {t("propertyTypeError")}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row md={3} sm={1} xs={1} className="mb-3">
              <Form.Group
                as={Col}
                className="mb-sm-2  mb-xs-3 mb-3"
                controlId="formGridEmail"
              >
                <Form.Label>{t("roomsAmount")}</Form.Label>
                <Form.Control
                  required
                  placeholder={t("roomsAmountPlaceholder")}
                />
                <Form.Control.Feedback type="invalid">
                  {t("roomsAmountError")}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>{t("squareMeters")}</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder={t("squareMetersPlaceholder")}
                />
                <Form.Control.Feedback type="invalid">
                  {t("squareMetersError")}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>{t("totalFloor")}</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder={t("totalFloorPlaceholder")}
                />
                <Form.Control.Feedback type="invalid">
                  {t("totalFloorError")}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row md={3} sm={1} xs={1} className="mb-3">
              <Form.Group
                as={Col}
                className="mb-sm-2  mb-xs-3 mb-3"
                controlId="formGridEmail"
              >
                <Form.Label>{t("departmentFloor")}</Form.Label>
                <Form.Control
                  required
                  placeholder={t("departmentFloorPlaceholder")}
                />
                <Form.Control.Feedback type="invalid">
                  {t("departmentFloorError")}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                className="mb-sm-2  mb-xs-3 mb-3"
                controlId="formGridEmail"
              >
                <Form.Label>{t("entryDate")}</Form.Label>
                <Form.Control
                  type="date"
                  required
                  placeholder={t("entryDatePlaceholder")}
                />
                <Form.Control.Feedback type="invalid">
                  {t("entryDateError")}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>Country</Form.Label>
                <Form.Select
                  onChange={(event) => {
                    setCountry(event.target.value);
                  }}
                  required
                >
                  <option value="">{t("selectcountry")}</option>
                  {Object.keys(countries).map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {t("countryError")}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>City</Form.Label>
                <Form.Select required>
                  <option value="">{t("selectcity")}</option>
                  {country !== "" &&
                    countries[country].map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {t("cityError")}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Label>{t("address")}</Form.Label>
              <Col>
                <Form.Group>
                  <Typeahead
                    id="autocomplete"
                    labelKey="place_name"
                    options={address.suggestions}
                    placeholder="Search..."
                    {...address}
                    onChange={handleSelect}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>{t("description")}</Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  placeholder={t("descriptionplaceholder")}
                />
                <Form.Control.Feedback type="invalid">
                  {t("descError")}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Label>{t("pleasetickabovefacility")}</Form.Label>
              <Col md={2} sm={4} xs={4}>
                <Form.Check
                  type="checkbox"
                  id="custom-switch"
                  label={t("elevator")}
                />
              </Col>
              <Col md={2} sm={4} xs={4}>
                <Form.Check type="checkbox" id="custom-switch" label="AC" />
              </Col>
              <Col md={2} sm={4} xs={4}>
                <Form.Check
                  type="checkbox"
                  id="custom-switch"
                  label={t("balcony")}
                />
              </Col>
              <Col md={2} sm={4} xs={4}>
                <Form.Check
                  type="checkbox"
                  id="custom-switch"
                  label={t("parking")}
                />
              </Col>
              <Col md={2} sm={4} xs={4}>
                <Form.Check type="checkbox" id="custom-switch" label="Bar" />
              </Col>
              <Col md={2} sm={4} xs={4}>
                <Form.Check
                  type="checkbox"
                  id="custom-switch"
                  label={t("warehouse")}
                />
              </Col>

              <Col md={2} sm={4} xs={4}>
                <Form.Check
                  type="checkbox"
                  id="custom-switch"
                  label={t("mmd")}
                />
              </Col>

              <Col md={2} sm={4} xs={4}>
                <Form.Check
                  type="checkbox"
                  id="custom-switch"
                  label={t("pandora")}
                />
              </Col>

              <Col md={2} sm={4} xs={4}>
                <Form.Check
                  type="checkbox"
                  id="custom-switch"
                  label={t("disabled")}
                />
              </Col>
            </Row>

            <Form.Group controlId="profileImage">
              <Form.Label>{t("propertypicture")}</Form.Label>
              <Row className="mb-3 SelectedImagesRow ">
                {!_.isEmpty(selectedImages) &&
                  selectedImages.map((image) => {
                    return (
                      <Col xs lg="2">
                        <div className="SelectedImagesContainer">
                          <img
                            alt="profile"
                            src={URL.createObjectURL(image)}
                            style={{
                              height: "100%",
                              width: "100%",
                            }}
                          />
                          <AiFillMinusCircle
                            onClick={() => handleRemove(image)}
                            color="red"
                            className="ClearImage"
                          />
                        </div>
                      </Col>
                    );
                  })}
                {selectedImages.length >= 3 ? null : (
                  <Col xs lg="2">
                    <label for="file-upload" className="CustomFileUpload">
                      <img
                        alt="profile"
                        src={require("../../assets/images/upload.png")}
                        style={{
                          height: "40%",
                          width: "40%",
                        }}
                      />
                      <span className="AddPhotoText">{t("addphoto")}</span>
                      <Form.Control
                        required
                        multiple
                        onChange={handleFileChange}
                        id="file-upload"
                        type="file"
                        name="profileImage"
                        style={{ display: "none" }}
                      />
                    </label>
                  </Col>
                )}
              </Row>
            </Form.Group>

            <div className="d-flex justify-content-end">
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </CustomModal>

        <CustomModal
          title={t("showproperty")}
          size="xl"
          show={isOpenViewModal}
          onHide={hideViewModal}
        >
          <Row>
            <Col>
              <Carousel>
                {selectedProperty?.files?.map((image) => {
                  return (
                    <Carousel.Item>
                      <img
                        className="d-block w-100 mx-auto"
                        src={
                          !isEmpty(selectedProperty?.files)
                            ? imageURL + image.name
                            : require("../../assets/images/no-image.jpg")
                        }
                        alt="First slide"
                      />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </Col>
            <Col
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              sm={12}
              md={5}
            >
              <Row className="mb-sm-4 mb-xs-4">
                <Card.Text className="PropertyTitleModalView">
                  {selectedProperty?.productName}
                </Card.Text>
                <Card.Text>
                  <i className="fa fa-map-marker GreenColor"></i>{" "}
                  {selectedProperty?.city}, {selectedProperty?.country}
                </Card.Text>
                <Card.Text className="PropertyPrice">
                  {t("dollar")}
                  {selectedProperty?.price}
                </Card.Text>
              </Row>
              {/* <div className="BuyCounterDiv"> */}
              {
                <Row>
                  <div className="PercentageContainer">
                    <Card.Text className="AvailablePercentage">
                      {selectedProperty?.percentage} % Available
                    </Card.Text>
                    <Card.Text className="AvailablePercentage">
                      {100 - selectedProperty?.percentage} % Sold
                    </Card.Text>
                  </div>
                </Row>
              }
              {/* </div> */}
            </Col>
          </Row>
          <HorizontalBar />
          <Row>
            <Col>
              <Card.Text className="PropertyDetailsText">
                {t("propertydetails")}
              </Card.Text>

              <Card.Text>
                {t("description")} : {selectedProperty?.description}
              </Card.Text>
              <Card.Text>
                {t("roomsAmount")} : {selectedProperty?.roomsAmount}
              </Card.Text>
              <Card.Text>
                {t("squareMeters")} : {selectedProperty?.squareMeters}
              </Card.Text>
              <Card.Text>
                {t("totalFloors")} : {selectedProperty?.totalFloor}
              </Card.Text>
              <Card.Text>
                {t("departmentFloor")} : {selectedProperty?.departmentFloor}
              </Card.Text>
              <Card.Text>
                {t("entryDate")} : {selectedProperty?.entryDate}
              </Card.Text>
            </Col>
          </Row>

          {/* {selectedProperty && selectedProperty.percentage === 0 && ( */}

          {/* )} */}
          <HorizontalBar />
          <Row>
            <Card.Text className="PropertyDetailsText">
              {t("propertyspecs")}
            </Card.Text>
            {selectedProperty?.ac && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                  marginTop: "0.7rem",
                }}
              >
                <i class="fa fa-snowflake-o" aria-hidden="true"></i>
                <span>{t("ac")}</span>
              </div>
            )}
            {selectedProperty?.balcony && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-home" aria-hidden="true"></i>
                <span>{t("balconythere")}</span>
              </div>
            )}
            {selectedProperty?.elevator && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-tablet" aria-hidden="true"></i>
                <span>{t("elevatorthere")}</span>
              </div>
            )}

            {selectedProperty?.parking && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-product-hunt" aria-hidden="true"></i>
                <span>{t("parkingthere")}</span>
              </div>
            )}

            {selectedProperty?.bar && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-circle-o" aria-hidden="true"></i>
                <span>{t("barthere")}</span>
              </div>
            )}

            {selectedProperty?.warehouse && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-home" aria-hidden="true"></i>
                <span>{t("warehousethere")}</span>
              </div>
            )}
            {selectedProperty?.mmd && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-shield" aria-hidden="true"></i>
                <span>{t("mmdthere")}</span>
              </div>
            )}
            {selectedProperty?.pandora && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-window-maximize" aria-hidden="true"></i>
                <span>{t("pandorathere")}</span>
              </div>
            )}
            {selectedProperty?.disabled && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <i class="fa fa-wheelchair-alt" aria-hidden="true"></i>
                <span>{t("disabledthere")}</span>
              </div>
            )}
          </Row>
          <Row>
            <HorizontalBar />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <span className="PropertyDetailsText mt-2 mb-4">
                {t("propertylocatedplace")}
              </span>
              <MapBox
                lat={selectedProperty?.longitude}
                lng={selectedProperty?.latitude}
              />
            </div>
          </Row>
        </CustomModal>

        <CustomModal
          title={t("updateproperty")}
          show={updateModal}
          onHide={handleCloseUpdateModal}
        >
          <Form noValidate validated={validated} onSubmit={handleUpdate}>
            <Row md={3} sm={1} xs={1} className="mb-3">
              <Form.Group
                as={Col}
                className="mb-sm-2  mb-xs-3 mb-3"
                controlId="formGridEmail"
              >
                <Form.Label>{t("title")}</Form.Label>
                <Form.Control
                  name="productName"
                  value={formData?.productName}
                  required
                  placeholder={t("titleplaceholder")}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {t("titleError")}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>{t("propertyamount")}</Form.Label>
                <Form.Control
                  disabled={formData?.percentage !== 100 ? true : false}
                  name="price"
                  value={formData?.price}
                  required
                  type="number"
                  placeholder={t("amountplaceholder")}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {t("amountError")}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>{t("productType")}</Form.Label>
                <Form.Select
                  name="productType"
                  value={formData?.productType}
                  required
                  defaultValue="Select Product Type"
                  onChange={handleChange}
                >
                  <option value={productType.SELL}>Sell</option>
                  <option value={productType.RENT}>Rent</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {t("propertyTypeError")}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row md={3} sm={1} xs={1} className="mb-3">
              <Form.Group
                as={Col}
                className="mb-sm-2  mb-xs-3 mb-3"
                controlId="formGridEmail"
              >
                <Form.Label>{t("roomsAmount")}</Form.Label>
                <Form.Control
                  name="roomsAmount"
                  value={formData?.roomsAmount}
                  required
                  placeholder={t("roomsAmountPlaceholder")}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {t("roomsAmountError")}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>{t("squareMeters")}</Form.Label>
                <Form.Control
                  name="squareMeters"
                  value={formData?.squareMeters}
                  required
                  type="number"
                  placeholder={t("squareMetersPlaceholder")}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {t("squareMetersError")}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>{t("totalFloor")}</Form.Label>
                <Form.Control
                  name="totalFloor"
                  value={formData?.totalFloor}
                  required
                  type="number"
                  placeholder={t("totalFloorPlaceholder")}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {t("totalFloorError")}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row md={3} sm={1} xs={1} className="mb-3">
              <Form.Group
                as={Col}
                className="mb-sm-2  mb-xs-3 mb-3"
                controlId="formGridEmail"
              >
                <Form.Label>{t("departmentFloor")}</Form.Label>
                <Form.Control
                  name="departmentFloor"
                  value={formData?.departmentFloor}
                  required
                  placeholder={t("departmentFloorPlaceholder")}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {t("departmentFloorError")}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                className="mb-sm-2  mb-xs-3 mb-3"
                controlId="formGridEmail"
              >
                <Form.Label>{t("entryDate")}</Form.Label>
                <Form.Control
                  name="entryDate"
                  value={formData?.entryDate}
                  type="date"
                  required
                  placeholder={t("entryDatePlaceholder")}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {t("entryDateError")}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>Country</Form.Label>
                <Form.Select
                  name="country"
                  value={formData?.country}
                  required
                  defaultValue="Select Country"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  <option value="">{t("selectcountry")}</option>
                  {Object.keys(countries).map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {t("countryError")}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>City</Form.Label>
                {console.log({ formData })}
                <Form.Select
                  value={formData?.city}
                  required
                  name="city"
                  onChange={handleChange}
                >
                  <option value="">{t("selectcity")}</option>
                  {country !== "" &&
                    countries[country].map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {t("cityError")}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Label>{t("address")}</Form.Label>
              <Col>
                <Form.Group>
                  <Typeahead
                    id="autocomplete"
                    labelKey="place_name"
                    options={address.suggestions}
                    placeholder="Search..."
                    {...address}
                    onChange={handleSelect}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>{t("description")}</Form.Label>
                <Form.Control
                  name="description"
                  value={formData?.description}
                  required
                  as="textarea"
                  placeholder={t("descriptionplaceholder")}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {t("descError")}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Label>{t("pleasetickabovefacility")}</Form.Label>
              <Col md={2} sm={4} xs={4}>
                <Form.Check
                  name="elevator"
                  type="checkbox"
                  id="custom-switch"
                  label={t("elevator")}
                  checked={formData?.elevator}
                  onChange={handleChecked}
                />
              </Col>
              <Col md={2} sm={4} xs={4}>
                <Form.Check
                  type="checkbox"
                  id="custom-switch"
                  label="AC"
                  checked={formData?.ac}
                  onChange={handleChecked}
                  name="ac"
                />
              </Col>
              <Col md={2} sm={4} xs={4}>
                <Form.Check
                  type="checkbox"
                  id="custom-switch"
                  label={t("balcony")}
                  checked={formData?.balcony}
                  onChange={handleChecked}
                  name="balcony"
                />
              </Col>
              <Col md={2} sm={4} xs={4}>
                <Form.Check
                  type="checkbox"
                  id="custom-switch"
                  label={t("parking")}
                  checked={formData?.parking}
                  onChange={handleChecked}
                  name="parking"
                />
              </Col>
              <Col md={2} sm={4} xs={4}>
                <Form.Check
                  type="checkbox"
                  id="custom-switch"
                  label="Bar"
                  checked={formData?.bar}
                  onChange={handleChecked}
                  name="bar"
                />
              </Col>
              <Col md={2} sm={4} xs={4}>
                <Form.Check
                  type="checkbox"
                  id="custom-switch"
                  label={t("warehouse")}
                  checked={formData?.warehouse}
                  onChange={handleChecked}
                  name="warehouse"
                />
              </Col>
              <Col md={2} sm={4} xs={4}>
                <Form.Check
                  type="checkbox"
                  id="custom-switch"
                  label={t("mmd")}
                  checked={formData?.mmd}
                  onChange={handleChecked}
                  name="mmd"
                />
              </Col>

              <Col md={2} sm={4} xs={4}>
                <Form.Check
                  type="checkbox"
                  id="custom-switch"
                  label={t("pandora")}
                  checked={formData?.pandora}
                  onChange={handleChecked}
                  name="pandora"
                />
              </Col>

              <Col md={2} sm={4} xs={4}>
                <Form.Check
                  type="checkbox"
                  id="custom-switch"
                  label={t("disabled")}
                  checked={formData?.disabled}
                  onChange={handleChecked}
                  name="disabled"
                />
              </Col>
            </Row>

            <Form.Group controlId="profileImage">
              <Form.Label>{t("propertypicture")}</Form.Label>
              <Row className="mb-3 SelectedImagesRow ">
                {!_.isEmpty(selectedUpdateImages) &&
                  selectedUpdateImages.map((image) => {
                    return (
                      <Col xs lg="2">
                        <div className="SelectedImagesContainer">
                          <img
                            alt="profile"
                            src={URL.createObjectURL(image)}
                            style={{
                              height: "100%",
                              width: "100%",
                            }}
                          />
                          <AiFillMinusCircle
                            onClick={() => handleRemoveUpdateImage(image)}
                            color="red"
                            className="ClearImage"
                          />
                        </div>
                      </Col>
                    );
                  })}
                {selectedImages.length >= 3 ? null : (
                  <Col xs lg="2">
                    <label for="file-upload" className="CustomFileUpload">
                      <img
                        alt="profile"
                        src={require("../../assets/images/upload.png")}
                        style={{
                          height: "40%",
                          width: "40%",
                        }}
                      />
                      <span className="AddPhotoText">{t("addphoto")}</span>
                      <Form.Control
                        multiple
                        onChange={handleUpdateFileChange}
                        id="file-upload"
                        type="file"
                        name="profileImage"
                        style={{ display: "none" }}
                        // value={selectedUpdateImages[0]?.name}
                      />
                    </label>
                  </Col>
                )}
              </Row>
            </Form.Group>

            <>
              <HorizontalBar />
              <Card.Text className="PropertyDetailsText">
                {t("uploadfile")}
              </Card.Text>
              <Row style={{ marginBottom: "1rem" }}>
                <Col md={3}>
                  <Form.Check
                    type="radio"
                    label={t("public")}
                    name="radio-buttons"
                    id="public-radio"
                    value="public"
                    checked={isPublic}
                    onChange={handleRadioChange}
                  />
                </Col>
                <Col md={3}>
                  <Form.Check
                    type="radio"
                    label={t("private")}
                    name="radio-buttons"
                    id="private-radio"
                    value="private"
                    checked={!isPublic}
                    onChange={handleRadioChange}
                  />
                </Col>
              </Row>
              <Form.Group style={{ marginBottom: "2rem" }} controlId="formFile">
                <Form.Control
                  type="file"
                  id="fileInput"
                  onChange={handleUploadChange}
                  className="d-none"
                  accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                />
                {_.isEmpty(formData?.filesPdf) ? (
                  <>
                    <button
                      type="button"
                      className="upload-button"
                      onClick={handleButtonClick}
                    >
                      {t("fileupload")}
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      style={{ backgroundColor: "red" }}
                      className="upload-button"
                      onClick={() => setdeleteFileShow(true)}
                    >
                      {t("fileremove")}
                    </button>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "2rem",
                      }}
                    >
                      <PdfOrWordViewer
                        url={pdfURL + formData?.filesPdf[0]?.name}
                      />
                    </div>
                  </>
                )}
                {uploadFile && (
                  <>
                    <Card
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <Card.Body>
                        {uploadFile && <p>{uploadFile?.name}</p>}
                        {/* <button
                          className="upload-button"
                          onClick={handleUpload}
                        >
                          {t("confirm")}
                        </button> */}
                      </Card.Body>
                    </Card>
                  </>
                )}
              </Form.Group>
            </>

            <div className="d-flex justify-content-end">
              <Button variant="primary" type="submit">
                {t("update")}
              </Button>
            </div>
          </Form>
        </CustomModal>

        <CustomModal
          size="md"
          title={t("deleteproperty")}
          show={deleteModal}
          onHide={handleCloseDeleteModal}
          footer={true}
          handleDelete={() => handleDelete(deleteProperty)}
        >
          <div>
            <Card.Text>Are you want to delete this property?</Card.Text>
          </div>
        </CustomModal>

        <CustomModal
          size="md"
          title={t("removefav")}
          show={deleteFavModal}
          onHide={handleCloseDeleteFavModal}
          footer={true}
          handleDelete={() => handleDeleteFav(deleteFavProperty)}
        >
          <div>
            <Card.Text>Are you want to remove from favorite basket?</Card.Text>
          </div>
        </CustomModal>

        <CustomModal
          size="md"
          title={t("fileremove")}
          show={deleteFileShow}
          onHide={() => {
            setdeleteFileShow(false);
          }}
          footer={true}
          handleDelete={handleDeleteFile}
        >
          <div>
            <Card.Text>{t("filedeleteconfirm")}</Card.Text>
          </div>
        </CustomModal>

        <CustomModal
          title={t("showproperty")}
          size="xl"
          show={isOpenManageRequest}
          onHide={() => setisOpenManageRequest(false)}
        >
          <Row>
            <Col>
              <Carousel>
                {selectedPendingProperty?.productDetails?.files.map((image) => {
                  return (
                    <Carousel.Item>
                      <img
                        className="d-block w-100 mx-auto"
                        src={
                          !isEmpty(
                            selectedPendingProperty?.productDetails?.files
                          )
                            ? imageURL + image.name
                            : require("../../assets/images/no-image.jpg")
                        }
                        alt="First slide"
                      />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </Col>
            <Col
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              sm={12}
              md={5}
            >
              <Row className="mb-sm-4 mb-xs-4">
                <Card.Text className="PropertyTitleModalView">
                  {selectedPendingProperty?.productDetails?.productName}
                </Card.Text>
                <Card.Text>
                  <i className="fa fa-map-marker GreenColor"></i>{" "}
                  {selectedPendingProperty?.productDetails?.city},{" "}
                  {selectedPendingProperty?.productDetails?.country}
                </Card.Text>
                <Card.Text className="PropertyPrice">
                  {t("dollar")} {selectedPendingProperty?.productDetails?.price}
                </Card.Text>
              </Row>
              {/* <div className="BuyCounterDiv"> */}
              {
                <Row>
                  <div className="PercentageContainer">
                    <Card.Text className="AvailablePercentage">
                      {selectedPendingProperty?.productDetails?.percentge} %
                      Available
                    </Card.Text>
                    <Card.Text className="AvailablePercentage">
                      {100 - selectedPendingProperty?.productDetails?.percentge}{" "}
                      % Sold
                    </Card.Text>
                    <Card.Text className="AvailablePercentage">
                      {selectedPendingProperty?.percentage} % Requested
                    </Card.Text>
                  </div>
                </Row>
              }
              {/* </div> */}
            </Col>
          </Row>
          <HorizontalBar />
          <Row>
            <Col>
              <Card.Text className="PropertyDetailsText">
                {t("propertydetails")}
              </Card.Text>
              <Card.Text>
                {selectedPendingProperty?.productDetails?.description}
              </Card.Text>
            </Col>
          </Row>

          <HorizontalBar />
          <Row>
            <Col>
              <Card.Text className="PropertyDetailsText">
                {t("userdetails")}
              </Card.Text>
              <Card.Text>
                First Name : {selectedPendingProperty?.buyer?.firstName}
              </Card.Text>
              <Card.Text>
                Last Name : {selectedPendingProperty?.buyer?.lastName}
              </Card.Text>
              <Card.Text>
                Email Address : {selectedPendingProperty?.buyer?.email}
              </Card.Text>
              <Card.Text>
                Contact Number : {selectedPendingProperty?.buyer?.phoneNumber}
              </Card.Text>
              {console.log({ selectedPendingProperty })}
              <Card.Text>
                Question : {selectedPendingProperty?.question}
              </Card.Text>
            </Col>
          </Row>
          {selectedPendingProperty?.status === "PENDING" && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                style={{ marginLeft: "1rem" }}
                onClick={() => updateStatus(selectedPendingProperty?.id)}
              >
                {t("accept")}
              </Button>
            </div>
          )}
        </CustomModal>

        <CustomModal
          title={t("showproperty")}
          size="xl"
          show={isVisible}
          onHide={hideModal}
        >
          <Row>
            {/* {selectedProperty?.productDetails?.pending && (
              <span
                style={{
                  fontSize: "1rem",
                  color: "red",
                  textAlign: "center",
                  marginBottom: "1rem",
                }}
              >
                {t("alreadybought")}
              </span>
            )} */}
            <Col>
              <Carousel>
                {selectedProperty?.productDetails?.files?.map((image) => {
                  return (
                    <Carousel.Item>
                      <img
                        className="d-block w-100 mx-auto"
                        src={
                          !isEmpty(selectedProperty?.productDetails?.files)
                            ? imageURL + image.name
                            : require("../../assets/images/no-image.jpg")
                        }
                        alt="First slide"
                      />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </Col>
            <Col
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              sm={12}
              md={5}
            >
              <Row id="buyButtonQuery" className="mb-sm-4 mb-xs-4">
                <Card.Text className="PropertyTitleModalView">
                  {selectedProperty?.productDetails?.productName}
                </Card.Text>
                <Card.Text>
                  <i className="fa fa-map-marker GreenColor"></i> &nbsp;
                  {selectedProperty?.productDetails?.city},{" "}
                  {selectedProperty?.productDetails?.country}
                </Card.Text>
                <Card.Text className="PropertyPrice">
                  {t("dollar")} {selectedProperty?.productDetails?.price}
                </Card.Text>
              </Row>

              <div className="BuyCounterDiv">
                {selectedProperty?.productDetails?.percentge !== 0 && (
                  <Row>
                    <div className="CounterDiv">
                      <blockquote
                        style={{ gap: "0.5rem" }}
                        className="blockquote d-flex mb-0"
                      >
                        <p
                          style={{
                            textAlign: "left",
                            color: "#149777",
                            fontSize: "0.8em",
                            fontWeight: "bold",
                          }}
                        >
                          {selectedProperty?.productDetails?.percentge}%{" "}
                          {t("available")}
                        </p>
                      </blockquote>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <FormControl
                          type="number"
                          placeholder="Enter a number between 5 and 100"
                          value={counter}
                          onChange={(e) => setCounter(e.target.value)}
                          min={5}
                          max={100}
                          required
                        />
                        {isError && (
                          <span style={{ fontSize: "0.7rem", color: "red" }}>
                            Percentage should 5 to{" "}
                            {selectedProperty?.productDetails?.percentge}
                          </span>
                        )}
                      </div>
                    </div>
                  </Row>
                )}

                <div
                  style={{ gap: "1rem" }}
                  className="d-flex align-items-center"
                >
                  <Button
                    className={
                      selectedProperty?.productDetails?.percentge === 0
                        ? "SoldButton"
                        : "BuyNowNormal"
                    }
                    disabled={
                      selectedProperty?.productDetails?.percentge === 0
                        ? true
                        : false
                    }
                    onClick={() => {
                      if (
                        /^\d+$/.test(counter) &&
                        counter >= 5 &&
                        counter <= 100
                      ) {
                        document
                          .querySelector("#details")
                          .scrollIntoView({ behavior: "smooth" });
                        setIsError(false);
                      } else {
                        setIsError(true);
                      }
                    }}
                  >
                    {selectedProperty?.productDetails?.percentge === 0
                      ? t("sold")
                      : t("buynow")}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>

          <HorizontalBar />

          <Row>
            <Col>
              <Card.Text className="PropertyDetailsText">
                {t("propertydetails")}
              </Card.Text>
              <Card.Text>
                {selectedProperty?.productDetails?.description}
              </Card.Text>
            </Col>
          </Row>

          <HorizontalBar />
          <Row>
            <Card.Text className="PropertyDetailsText">
              {t("propertyspecs")}
            </Card.Text>
            {selectedProperty?.productDetails?.ac && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                  marginTop: "0.7rem",
                }}
              >
                <i class="fa fa-snowflake-o" aria-hidden="true"></i>
                <span>{t("ac")}</span>
              </div>
            )}
            {selectedProperty?.productDetails?.balcony && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-home" aria-hidden="true"></i>
                <span>{t("balconythere")}</span>
              </div>
            )}
            {selectedProperty?.productDetails?.elevator && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-tablet" aria-hidden="true"></i>
                <span>{t("elevatorthere")}</span>
              </div>
            )}

            {selectedProperty?.productDetails?.parking && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-product-hunt" aria-hidden="true"></i>
                <span>{t("parkingthere")}</span>
              </div>
            )}

            {selectedProperty?.productDetails?.bar && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-circle-o" aria-hidden="true"></i>
                <span>{t("barthere")}</span>
              </div>
            )}

            {selectedProperty?.productDetails?.warehouse && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-home" aria-hidden="true"></i>
                <span>{t("warehousethere")}</span>
              </div>
            )}
            {selectedProperty?.productDetails?.mmd && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-shield" aria-hidden="true"></i>
                <span>{t("mmdthere")}</span>
              </div>
            )}
            {selectedProperty?.productDetails?.pandora && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-window-maximize" aria-hidden="true"></i>
                <span>{t("pandorathere")}</span>
              </div>
            )}
            {selectedProperty?.productDetails?.disabled && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <i class="fa fa-wheelchair-alt" aria-hidden="true"></i>
                <span>{t("disabledthere")}</span>
              </div>
            )}
          </Row>

          <Row>
            <HorizontalBar />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <span className="PropertyDetailsText mt-2 mb-4">
                {t("propertylocatedplace")}
              </span>
              <MapBox
                lat={selectedProperty?.productDetails?.longitude}
                lng={selectedProperty?.productDetails?.latitude}
              />
            </div>
          </Row>

          {selectedProperty?.productDetails?.percentge !== 0 && (
            <HorizontalBar />
          )}

          {selectedProperty?.productDetails?.percentge !== 0 && (
            <Row id="details">
              <Card.Text className="PropertyDetailsText">
                {t("fillyourdetails")}
              </Card.Text>
              <Card.Text className="WarningPercentage">
                {t("confirmbeforebuy")}
              </Card.Text>
              <Form noValidate validated={validated} onSubmit={handleBuy}>
                {/* <Row md={3} sm={1} xs={1} className="mb-3">
                  <Form.Group
                    as={Col}
                    className="mb-sm-2 mb-md-3 mb-xs-3 mb-3"
                    controlId="formGridEmail"
                  >
                    <Form.Label>{t("firstname")}</Form.Label>
                    <Form.Control
                      required
                      placeholder={t("firstnameplaceholder")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("firstnameerror")}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    className="mb-sm-2 mb-md-3 mb-xs-3 mb-3"
                    controlId="formGridEmail"
                  >
                    <Form.Label>{t("lastname")}</Form.Label>
                    <Form.Control
                      required
                      placeholder={t("lastnameplaceholder")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("lastnameerror")}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    className="mb-sm-2 mb-md-3 mb-xs-3 mb-3"
                    controlId="formGridEmail"
                  >
                    <Form.Label>{t("email")}</Form.Label>
                    <Form.Control required placeholder={t("emailplacehoder")} />
                    <Form.Control.Feedback type="invalid">
                      {t("emailerror")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row> */}

                <Row md={3} sm={1} xs={1} className="mb-3">
                  {/* <Form.Group
                    as={Col}
                    className="mb-sm-2 mb-md-3 mb-xs-3 mb-3"
                    controlId="formGridEmail"
                  >
                    <Form.Label>{t("phonenumber")}</Form.Label>
                    <Form.Control
                      required
                      placeholder={t("phoneplaceholder")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("phonenumbererror")}
                    </Form.Control.Feedback>
                  </Form.Group> */}

                  {/* <Form.Group
                    className="mb-sm-2 mb-md-3 mb-xs-3 mb-3"
                    as={Col}
                    controlId="formGridState"
                  >
                    <Form.Label>{t("yourcountry")}</Form.Label>
                    <Form.Select
                      onChange={(event) => {
                        setBuyOptionCountry(event.target.value);
                      }}
                      required
                    >
                      <option value={""}>{t("selectcountry")}</option>
                      {Object.keys(countries).map((country) => (
                        <option key={country} value={country}>
                          {country}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {t("countryError")}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridState">
                    <Form.Label>{t("yourcity")}</Form.Label>
                    <Form.Select required>
                      <option value={""}>{t("selectcity")}</option>
                      {buyOptionCountry !== "" &&
                        countries[buyOptionCountry].map((city) => (
                          <option key={city} value={city}>
                            {city}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {t("cityError")}
                    </Form.Control.Feedback>
                  </Form.Group> */}
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridState">
                    <Form.Label>{t("anyquestions")}</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder={t("questionplaceholder")}
                    />
                  </Form.Group>
                </Row>

                <div className="d-flex justify-content-end">
                  <Button
                    disabled={confirmDisable}
                    variant="primary"
                    type="submit"
                  >
                    {t("confirm")}
                  </Button>
                </div>
              </Form>
            </Row>
          )}
        </CustomModal>

        <Footer />
      </LoadingOverlay>
    </section>
  );
}

export default Dashboard;
