import React from "react";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { imageURL } from "../../api/apis";
import { productType } from "../../shared/constants";

export default function PropertyCard(props) {
  const {
    isAgent = false,
    onClick,
    title,
    price,
    handleAddFavourite,
    country,
    city,
    type,
    images,
    isSold,
    isLiked,
    isShowLike = true,
    isHaveStatus = null,
    isShowBuy = false,
    onClickBuy,
  } = props;
  const { t } = useTranslation();
  return (
    <Card className="project-card-view">
      <Card.Img
        onClick={onClick}
        variant="top"
        src={
          images[0]?.name
            ? imageURL + images[0]?.name
            : require("../../assets/images/no-image.jpg")
        }
        style={{ height: "13rem", cursor: "pointer" }}
      />
      <Card.Body style={{ textAlign: "left" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <blockquote className="blockquote mb-0">
              <strong className="purple">
                {" "}
                {t("dollar")} {price}
              </strong>
            </blockquote>
            <blockquote className="blockquote mb-0">
              <p
                style={{
                  textAlign: "left",
                  color: "#000",
                  fontSize: "0.9em",
                  fontWeight: "bold",
                }}
              >
                {title}
              </p>
            </blockquote>
          </div>
          {isShowLike && (
            <div
              onClick={handleAddFavourite}
              className="FavouriteHeartI"
              style={{
                backgroundColor: isLiked ? "#ffdede" : "#f5f5f5",
              }}
            >
              <i
                style={{
                  color: isLiked ? "#cd0909" : "#808080",
                }}
                class={isLiked ? "fa fa-heart" : "fa fa-heart-o"}
                aria-hidden="true"
              ></i>
            </div>
          )}
        </div>
        <blockquote
          style={{ gap: "0.5rem" }}
          className="blockquote d-flex mb-0"
        >
          <i className="fa fa-map-marker GreenColor"></i>
          <p
            style={{
              textAlign: "left",
              color: "#000",
              fontSize: "0.7em",
            }}
          >
            {city}, {country}
          </p>
        </blockquote>
        {isSold && (
          <span className="SaleOrRentText">
            <Badge className="SaleOrRent red-badge-bg" bg="#FF0000">
              {t("sold")}
            </Badge>
          </span>
        )}

        {isHaveStatus !== null && (
          <span className="SaleOrRentTextI">
            <Badge className="SaleOrRentI">
              {isHaveStatus === "PENDING"
                ? "AWAITING"
                : isHaveStatus === "CONFIRMED"
                ? "CONFIRMED"
                : ""}
            </Badge>
          </span>
        )}

        {type === productType.RENT && !isSold ? (
          <span className="SaleOrRentText">
            <Badge className="SaleOrRent" bg="#149777">
              {t("forrent")}
            </Badge>
          </span>
        ) : type === productType.SELL && !isSold ? (
          <span className="SaleOrRentText">
            <Badge className="SaleOrRent" bg="#149777">
              {t("forsale")}
            </Badge>
          </span>
        ) : (
          ""
        )}

        {isAgent ? (
          <div className="ManagePropertyContainer">
            <div
              style={{ backgroundColor: "#90EE90" }}
              className="ManagePropertyIconCircle"
            >
              <AiFillEdit />
            </div>

            <div
              style={{ backgroundColor: "#FFCCCB" }}
              className="ManagePropertyIconCircle"
            >
              <AiFillDelete />
            </div>
          </div>
        ) : (
          <></>
        )}

        {isShowBuy && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={onClickBuy}
          >
            <Button style={{ height: 40, width: "40%", fontSize: "0.9rem" }}>
              Buy Now
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}
