import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { toast, Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { getAllUsers, updateUserStatus } from "../../api/apis";
import Footer from "../../components/footer/Footer";
import NavBar from "../../components/navBar/NavBar";
import Switch from "react-switch";

export default function Administrator() {
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const getAllUsersFunction = async () => {
    try {
      const response = await getAllUsers();
      setData(response);
    } catch (error) {}
  };

  const handleChange = async (checked, user) => {
    let prevData = [...data];
    const updatedData = prevData.map((item) => {
      if (item.id === user.id) {
        return {
          ...item,
          ...{
            userName: user.userName,
            email: user.email,
            roleName: user.roleName,
            status: checked,
          },
        };
      }
      return item;
    });
    setData(updatedData);
    updateUserStatus(user.id)
      .then((res) => {
        toast.success("User status update succesfully.");
      })
      .catch((err) => {
        toast.error("Error while update status!");
      });
  };

  useEffect(() => {
    getAllUsersFunction();
  }, []);

  return (
    <section>
      <Toaster />
      <NavBar />
      <Container fluid className="home-section" id="home">
        <Row className="home-header-row">
          <Col md={8} className="home-header">
            <span
              style={{
                paddingBottom: 15,
                fontSize: "100px",
                textAlign: "center",
                color: "#fff",
              }}
              className="heading"
            >
              {t("estateusers")}
            </span>
          </Col>
        </Row>
      </Container>
      <Container
        style={{ minHeight: window.innerHeight - 100 }}
        fluid
        className="home-about-section"
      >
        <h1
          style={{
            fontSize: "2.1em",
            paddingBottom: "20px",
            paddingTop: "1rem",
          }}
        >
          <strong>{t("registered")}</strong>{" "}
          <strong className="purple">{t("users")}</strong>
        </h1>
        <Table responsive striped bordered hover size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Type</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {data.map((user, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>{`${user?.firstName} ${user?.lastName}`}</td>
                  <td>{user?.email}</td>
                  <td>{user?.roleName}</td>
                  <td>
                    <Switch
                      onChange={(checked) => handleChange(checked, user)}
                      checked={user?.status}
                      onColor="#149777"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>
      <Footer />
    </section>
  );
}
