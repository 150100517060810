import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Carousel,
  Card,
  Button,
  Form,
  FormControl,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CustomModal } from "../../components/customModal/CustomModal";
import Footer from "../../components/footer/Footer";
import HorizontalBar from "../../components/horizontalBar/HorizontalBar";
import NavBar from "../../components/navBar/NavBar";
import PropertyCard from "../../components/propertyCard/PropertyCard";
import { useDispatch, useSelector } from "react-redux";
import { getAllPostAction } from "../../redux/actions/propertyAction";
import { productType } from "../../shared/constants";
import _, { isEmpty } from "lodash";
import countries from "../../shared/countries.json";
import {
  addFavourite,
  buyProperty,
  deleteFavourite,
  getlocations,
  imageURL,
  pdfURL,
} from "../../api/apis";
import { toast, Toaster } from "react-hot-toast";
// import "./slider.less";
import "rc-slider/assets/index.css";
import MapBox from "../../components/map/Map";
import GoogleDocsViewer from "react-google-docs-viewer";

const PdfOrWordViewer = ({ url }) => {
  const { t } = useTranslation();
  const extension = url.match(/\.(pdf|docx)$/)[1];
  if (extension === "pdf") {
    return <GoogleDocsViewer width="600px" height="600px" fileUrl={url} />;
  } else if (extension === "docx") {
    return (
      <Card
        style={{
          width: "15rem",
        }}
      >
        <Card.Img
          variant="top"
          src="https://play-lh.googleusercontent.com/FOIfyL9U8PcmbnfDjcpdUWNZcWLMY24p5SrGERmRr_K-yeqv5ZzjJ81B4ZfmV07kg_Yu"
        />
        <Card.Body style={{ textAlign: "center" }}>
          <Card.Text>{t("documentedfile")}</Card.Text>
          <Button href={url} variant="primary">
            {t("download")}
          </Button>
        </Card.Body>
      </Card>
    );
  } else {
    return <p>Unsupported file type</p>;
  }
};

function Home() {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [counter, setCounter] = useState(5);
  const [selectedProperty, setSelectedProperty] = useState();
  const [propertyType, setPropertyType] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [validated, setValidated] = useState(false);
  const [buyOptionCountry, setBuyOptionCountry] = useState("");
  const [locations, setLocations] = useState([]);
  const [isError, setIsError] = useState(false);
  const [startPrice, setStartPrice] = useState("");
  const [endPrice, setEndPrice] = useState("");
  const [alreadyBoughtModal, setalreadyBoughtModal] = useState(false);
  const [alreadyBoughtModalII, setalreadyBoughtModalII] = useState(false);
  const [confirmDisable, setconfirmDisable] = useState(false);

  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.propertyReducer);
  const userId = JSON.parse(localStorage.getItem("user")).id;

  const getAllLocations = () => {
    getlocations()
      .then((res) => {
        setLocations(res);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getAllLocations();
    dispatch(
      getAllPostAction({
        productType: propertyType,
        country: country,
        city: city,
        startPrice: startPrice,
        endPrice: endPrice,
        userId: userId,
      })
    );
  }, []);

  const openModal = (data) => {
    setSelectedProperty(data);
    setIsVisible(true);
  };
  const hideModal = () => {
    setIsVisible(false);
  };

  const handleBuyProperty = async (data) => {
    setconfirmDisable(true);
    buyProperty(data)
      .then((res) => {
        hideModal();
        toast.success("Request sent successfully, Agent will contact you.");
        setCounter(5);
        dispatch(
          getAllPostAction({
            productType: propertyType,
            country: country,
            city: city,
            startPrice: startPrice,
            endPrice: endPrice,
            userId: userId,
          })
        );
        setconfirmDisable(false);
      })
      .catch((err) => {
        setconfirmDisable(false);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (/^\d+$/.test(counter) && counter >= 5 && counter <= 100) {
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        setValidated(true);
      } else {
        const userId = JSON.parse(localStorage.getItem("user")).id;
        const user = JSON.parse(localStorage.getItem("user"));
        const payload = {
          percentage: counter,
          productDetailsId: selectedProperty.id,
          buyerId: userId,
          agentId: selectedProperty.userId,
          firstname: user?.firstName,
          lastname: user?.lastName,
          email: user?.email,
          phoneNumber: user?.phoneNumber,
          question: e.target[0]?.value,
          address: selectedProperty?.address,
        };
        handleBuyProperty(payload);
      }
    } else {
      setIsError(true);
      document
        .querySelector("#buyButtonQuery")
        .scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleChangeFavorite = (isLiked, id) => {
    const userId = JSON.parse(localStorage.getItem("user")).id;
    const payload = {
      userId: userId,
      productDetailsId: id,
    };
    isLiked ? handleDelete(payload) : handleAddFavourite(id);
  };

  const handleDelete = async (payload) => {
    try {
      const data = await deleteFavourite(payload);
      toast.success("This property removed your favorite.");
      dispatch(
        getAllPostAction({
          productType: propertyType,
          country: country,
          city: city,
          startPrice: startPrice,
          endPrice: endPrice,
          userId: userId,
        })
      );
    } catch (error) {}
  };

  const handleAddFavourite = (propertyId) => {
    const userId = JSON.parse(localStorage.getItem("user")).id;
    const payload = {
      userId: userId,
      productDetailsId: propertyId,
    };
    addFavourite(payload)
      .then((res) => {
        toast.success("This property added into your favorite.");
        dispatch(
          getAllPostAction({
            productType: propertyType,
            country: country,
            city: city,
            startPrice: startPrice,
            endPrice: endPrice,
            userId: userId,
          })
        );
      })
      .catch((err) => {
        toast.error(err.data.validationFailures[0]?.message);
      });
  };

  return (
    <section>
      <Toaster />
      <NavBar />
      <Container fluid className="home-section" id="home">
        <Row className="home-header-row">
          <Col md={8} className="home-header">
            <span
              style={{
                paddingBottom: 15,
                fontSize: "100px",
                textAlign: "center",
                color: "#fff",
              }}
              className="heading"
            >
              {t("discovermostsuitable")} <br /> {t("property")}
            </span>
          </Col>
        </Row>
        <Row
          md={3}
          sm={1}
          xs={1}
          className="mb-3 d-flex justify-content-center align-items-center"
        >
          <Col md={3}>
            <Form.Group
              as={Col}
              className="mb-sm-2 mb-md-3 mb-xs-3 mb-3"
              controlId="formGridEmail"
            >
              <Form.Select
                className="SearchInput"
                defaultValue="Select Country"
                onChange={(event) => {
                  setCountry(event.target.value);
                  if (event.target.value === "") setCity("");
                  dispatch(
                    getAllPostAction({
                      productType: propertyType,
                      country: event.target.value,
                      city: event.target.value === "" ? "" : city,
                      startPrice: startPrice,
                      endPrice: endPrice,
                      userId: userId,
                    })
                  );
                }}
                value={country}
              >
                <option value="">{t("allcountries")}</option>
                {Object.keys(locations).map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group
              as={Col}
              className="mb-sm-2 mb-md-3 mb-xs-3 mb-3"
              controlId="formGridEmail"
            >
              <Form.Select
                className="SearchInput"
                defaultValue="Select Country"
                onChange={(event) => {
                  setCity(event.target.value);
                  dispatch(
                    getAllPostAction({
                      productType: propertyType,
                      country: country,
                      city: event.target.value,
                      startPrice: startPrice,
                      endPrice: endPrice,
                      userId: userId,
                    })
                  );
                }}
                value={city}
              >
                <option value="">{t("allcities")}</option>
                {country !== "" &&
                  locations[country]?.map((city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col md={5}>
            <Card
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                padding: 5,
              }}
              className="mb-sm-2 mb-md-3 mb-xs-3 mb-3"
            >
              <Row>
                <Col>
                  <FormControl
                    type="number"
                    placeholder={`Min ${t("dollar")}`}
                    value={start}
                    onChange={(e) => setStart(e.target.value)}
                    min={0}
                  />
                </Col>
                <Col>
                  <FormControl
                    type="number"
                    placeholder={`Max ${t("dollar")}`}
                    value={end}
                    onChange={(e) => setEnd(e.target.value)}
                    min={0}
                  />
                </Col>
                <Col>
                  <div
                    style={{
                      backgroundColor: "#149777",
                      height: "2.4rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      setStartPrice(start);
                      setEndPrice(end);
                      dispatch(
                        getAllPostAction({
                          productType: "",
                          country: country,
                          city: city,
                          startPrice: start,
                          endPrice: end,
                          userId: userId,
                        })
                      );
                    }}
                  >
                    <i
                      style={{ color: "#fff" }}
                      class="fa fa-arrow-circle-right"
                      aria-hidden="true"
                    ></i>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid className="home-about-section" id="about">
        <Row
          style={{ borderBottom: "1px solid #ebdfdf" }}
          className="d-flex justify-content-center align-items-center mb-4"
        >
          <Col
            className="d-flex justify-content-center align-items-center"
            md={1}
            xs={4}
            onClick={() => {
              setPropertyType("");
              dispatch(
                getAllPostAction({
                  productType: "",
                  country: country,
                  city: city,
                  startPrice: startPrice,
                  endPrice: endPrice,
                  userId: userId,
                })
              );
            }}
          >
            <div
              className={
                propertyType === ""
                  ? "SelectedTypeFilterOption"
                  : "TypeFilterOption"
              }
            >
              <div>
                <span>{t("imlookingall")}</span>
              </div>
            </div>
          </Col>

          <Col
            className="d-flex justify-content-center align-items-center"
            md={2}
            xs={4}
            onClick={() => {
              setPropertyType(productType.SELL);
              dispatch(
                getAllPostAction({
                  productType: productType.SELL,
                  country: country,
                  city: city,
                  startPrice: startPrice,
                  endPrice: endPrice,
                  userId: userId,
                })
              );
            }}
          >
            <div
              className={
                propertyType === productType.SELL
                  ? "SelectedTypeFilterOption"
                  : "TypeFilterOption"
              }
            >
              <div>
                <span>{t("imlookingforbuy")}</span>
              </div>
            </div>
          </Col>
          <Col
            className="d-flex justify-content-center align-items-center"
            md={2}
            xs={4}
            onClick={() => {
              setPropertyType(productType.RENT);
              dispatch(
                getAllPostAction({
                  productType: productType.RENT,
                  country: country,
                  city: city,
                  startPrice: startPrice,
                  endPrice: endPrice,
                  userId: userId,
                })
              );
            }}
          >
            <div
              className={
                propertyType === productType.RENT
                  ? "SelectedTypeFilterOption"
                  : "TypeFilterOption"
              }
            >
              <div>
                <span>{t("imlookingforrent")}</span>
              </div>
            </div>
          </Col>
        </Row>
        <Row
          className="g-4"
          style={{ justifyContent: "center", paddingBottom: "10px" }}
        >
          {!_.isEmpty(data) ? (
            data?.map((property) => (
              <Col md={3} xs={12} className="property-card" key={property}>
                <PropertyCard
                  images={property?.files}
                  title={property.productName}
                  price={property.price}
                  country={property.country}
                  city={property.city}
                  onClick={() => openModal(property)}
                  type={property.productType}
                  isSold={property?.percentage === 0}
                  isLiked={property?.like}
                  handleAddFavourite={() =>
                    handleChangeFavorite(property?.like, property?.id)
                  }
                  isShowBuy={true}
                  onClickBuy={() => {
                    console.log("++++++++", property);
                    setSelectedProperty(property);
                    if (property?.pending) {
                      setalreadyBoughtModalII(true);
                    } else {
                      openModal(property);
                    }
                  }}
                />
              </Col>
            ))
          ) : (
            <div style={{ marginTop: "5rem" }}>
              <img
                alt="not-data"
                src={require("../../assets/images/no-data.png")}
                style={{ height: "10rem", width: "10rem" }}
              />

              <blockquote className="blockquote mb-0">
                <p style={{ textAlign: "center", color: "#000" }}>
                  No Properties Available
                </p>
              </blockquote>
            </div>
          )}
        </Row>
      </Container>
      <CustomModal
        title={t("showproperty")}
        size="xl"
        show={isVisible}
        onHide={hideModal}
      >
        <Row>
          {/* {selectedProperty?.pending && (
            <span
              style={{
                fontSize: "1rem",
                color: "red",
                textAlign: "center",
                marginBottom: "1rem",
              }}
            >
              {t("alreadybought")}
            </span>
          )} */}
          <Col>
            <Carousel>
              {selectedProperty?.files?.map((image) => {
                return (
                  <Carousel.Item>
                    <img
                      className="d-block w-100 mx-auto"
                      src={
                        !isEmpty(selectedProperty?.files)
                          ? imageURL + image.name
                          : require("../../assets/images/no-image.jpg")
                      }
                      alt="First slide"
                    />
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </Col>
          <Col
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            sm={12}
            md={5}
          >
            <Row id="buyButtonQuery" className="mb-sm-4 mb-xs-4">
              <Card.Text className="PropertyTitleModalView">
                {selectedProperty?.productName}
              </Card.Text>
              <Card.Text>
                <i className="fa fa-map-marker GreenColor"></i> &nbsp;
                {selectedProperty?.city}, {selectedProperty?.country}
              </Card.Text>
              <Card.Text className="PropertyPrice">
                {t("dollar")} {selectedProperty?.price}
              </Card.Text>
            </Row>

            <div className="BuyCounterDiv">
              {selectedProperty?.percentage !== 0 && (
                <Row>
                  <div className="CounterDiv">
                    <blockquote
                      style={{ gap: "0.5rem" }}
                      className="blockquote d-flex mb-0"
                    >
                      <p
                        style={{
                          textAlign: "left",
                          color: "#149777",
                          fontSize: "0.8em",
                          fontWeight: "bold",
                        }}
                      >
                        {selectedProperty?.percentage}% {t("available")}
                      </p>
                    </blockquote>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <FormControl
                        type="number"
                        placeholder="Enter a number between 5 and 100"
                        value={counter}
                        onChange={(e) => setCounter(e.target.value)}
                        min={5}
                        max={100}
                        required
                      />
                      {isError && (
                        <span style={{ fontSize: "0.7rem", color: "red" }}>
                          Percentage should 5 to {selectedProperty?.percentage}
                        </span>
                      )}
                    </div>
                  </div>
                </Row>
              )}

              <div
                style={{ gap: "1rem" }}
                className="d-flex align-items-center"
              >
                <Button
                  className={
                    selectedProperty?.percentage === 0
                      ? "SoldButton"
                      : "BuyNowNormal"
                  }
                  disabled={selectedProperty?.percentage === 0 ? true : false}
                  onClick={() => {
                    if (
                      /^\d+$/.test(counter) &&
                      counter >= 5 &&
                      counter <= 100
                    ) {
                      if (selectedProperty?.pending) {
                        setalreadyBoughtModal(true);
                      } else {
                        document
                          .querySelector("#details")
                          .scrollIntoView({ behavior: "smooth" });
                        setIsError(false);
                      }
                    } else {
                      setIsError(true);
                    }
                  }}
                >
                  {selectedProperty?.percentage === 0 ? t("sold") : t("buynow")}
                </Button>

                <div
                  onClick={() => handleAddFavourite(selectedProperty?.id)}
                  className="FavouriteHeart"
                >
                  <i class="fa fa-heart" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <HorizontalBar />
        {console.log({ selectedProperty })}
        <Row>
          <Col>
            <Card.Text className="PropertyDetailsText">
              {t("propertydetails")}
            </Card.Text>
            <Card.Text style={{ color: "#149777", fontWeight: "bold" }}>
              {t("agentname")}: {selectedProperty?.agentName}
            </Card.Text>

            <Card.Text>
              {t("description")} : {selectedProperty?.description}
            </Card.Text>
            <Card.Text>
              {t("roomsAmount")} : {selectedProperty?.roomsAmount}
            </Card.Text>
            <Card.Text>
              {t("squareMeters")} : {selectedProperty?.squareMeters}
            </Card.Text>
            <Card.Text>
              {t("totalFloors")} : {selectedProperty?.totalFloor}
            </Card.Text>
            <Card.Text>
              {t("departmentFloor")} : {selectedProperty?.departmentFloor}
            </Card.Text>
            <Card.Text>
              {t("entryDate")} : {selectedProperty?.entryDate}
            </Card.Text>
          </Col>
        </Row>

        <HorizontalBar />
        <Row>
          <Card.Text className="PropertyDetailsText">
            {t("propertyspecs")}
          </Card.Text>
          {selectedProperty?.ac && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "1rem",
                marginBottom: "1rem",
                marginTop: "0.7rem",
              }}
            >
              <i class="fa fa-snowflake-o" aria-hidden="true"></i>
              <span>{t("ac")}</span>
            </div>
          )}
          {selectedProperty?.balcony && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "1rem",
                marginBottom: "1rem",
              }}
            >
              <i class="fa fa-home" aria-hidden="true"></i>
              <span>{t("balconythere")}</span>
            </div>
          )}
          {selectedProperty?.elevator && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "1rem",
                marginBottom: "1rem",
              }}
            >
              <i class="fa fa-tablet" aria-hidden="true"></i>
              <span>{t("elevatorthere")}</span>
            </div>
          )}

          {selectedProperty?.parking && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "1rem",
                marginBottom: "1rem",
              }}
            >
              <i class="fa fa-product-hunt" aria-hidden="true"></i>
              <span>{t("parkingthere")}</span>
            </div>
          )}

          {selectedProperty?.bar && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "1rem",
                marginBottom: "1rem",
              }}
            >
              <i class="fa fa-circle-o" aria-hidden="true"></i>
              <span>{t("barthere")}</span>
            </div>
          )}

          {selectedProperty?.warehouse && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "1rem",
                marginBottom: "1rem",
              }}
            >
              <i class="fa fa-home" aria-hidden="true"></i>
              <span>{t("warehousethere")}</span>
            </div>
          )}
          {selectedProperty?.mmd && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "1rem",
                marginBottom: "1rem",
              }}
            >
              <i class="fa fa-shield" aria-hidden="true"></i>
              <span>{t("mmdthere")}</span>
            </div>
          )}
          {selectedProperty?.pandora && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "1rem",
                marginBottom: "1rem",
              }}
            >
              <i class="fa fa-window-maximize" aria-hidden="true"></i>
              <span>{t("pandorathere")}</span>
            </div>
          )}
          {selectedProperty?.disabled && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <i class="fa fa-wheelchair-alt" aria-hidden="true"></i>
              <span>{t("disabledthere")}</span>
            </div>
          )}
        </Row>

        <Row>
          <HorizontalBar />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <span className="PropertyDetailsText mt-2 mb-4">
              {t("propertylocatedplace")}
            </span>
            <MapBox
              lat={selectedProperty?.longitude}
              lng={selectedProperty?.latitude}
            />
          </div>
        </Row>

        {!_.isEmpty(selectedProperty?.filesPdf) &&
          !selectedProperty?.pdfStatus && (
            <>
              <HorizontalBar />
              <Card.Text className="PropertyDetailsText">
                {t("uploadfile")}
              </Card.Text>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2rem",
                }}
              >
                <PdfOrWordViewer
                  url={pdfURL + selectedProperty?.filesPdf[0]?.name}
                />
              </div>
            </>
          )}

        {selectedProperty?.percentage !== 0 && <HorizontalBar />}

        {selectedProperty?.percentage !== 0 && (
          <Row id="details">
            <Card.Text className="PropertyDetailsText">
              {t("fillyourdetails")}
            </Card.Text>
            <Card.Text className="WarningPercentage">
              {t("confirmbeforebuy")}
            </Card.Text>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              {/* <Row md={3} sm={1} xs={1} className="mb-3">
                <Form.Group
                  as={Col}
                  className="mb-sm-2 mb-md-3 mb-xs-3 mb-3"
                  controlId="formGridEmail"
                >
                  <Form.Label>{t("firstname")}</Form.Label>
                  <Form.Control
                    required
                    placeholder={t("firstnameplaceholder")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t("firstnameerror")}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  as={Col}
                  className="mb-sm-2 mb-md-3 mb-xs-3 mb-3"
                  controlId="formGridEmail"
                >
                  <Form.Label>{t("lastname")}</Form.Label>
                  <Form.Control
                    required
                    placeholder={t("lastnameplaceholder")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t("lastnameerror")}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  as={Col}
                  className="mb-sm-2 mb-md-3 mb-xs-3 mb-3"
                  controlId="formGridEmail"
                >
                  <Form.Label>{t("email")}</Form.Label>
                  <Form.Control required placeholder={t("emailplacehoder")} />
                  <Form.Control.Feedback type="invalid">
                    {t("emailerror")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row> */}

              <Row md={3} sm={1} xs={1} className="mb-3">
                {/* <Form.Group
                  as={Col}
                  className="mb-sm-2 mb-md-3 mb-xs-3 mb-3"
                  controlId="formGridEmail"
                >
                  <Form.Label>{t("phonenumber")}</Form.Label>
                  <Form.Control required placeholder={t("phoneplaceholder")} />
                  <Form.Control.Feedback type="invalid">
                    {t("phonenumbererror")}
                  </Form.Control.Feedback>
                </Form.Group> */}

                {/* <Form.Group
                  className="mb-sm-2 mb-md-3 mb-xs-3 mb-3"
                  as={Col}
                  controlId="formGridState"
                >
                  <Form.Label>{t("yourcountry")}</Form.Label>
                  <Form.Select
                    onChange={(event) => {
                      setBuyOptionCountry(event.target.value);
                    }}
                    required
                  >
                    <option value={""}>{t("selectcountry")}</option>
                    {Object.keys(countries).map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {t("countryError")}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridState">
                  <Form.Label>{t("yourcity")}</Form.Label>
                  <Form.Select required>
                    <option value={""}>{t("selectcity")}</option>
                    {buyOptionCountry !== "" &&
                      countries[buyOptionCountry].map((city) => (
                        <option key={city} value={city}>
                          {city}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {t("cityError")}
                  </Form.Control.Feedback>
                </Form.Group> */}
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridState">
                  <Form.Label>{t("anyquestions")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder={t("questionplaceholder")}
                  />
                </Form.Group>
              </Row>

              <div className="d-flex justify-content-end">
                <Button
                  disabled={confirmDisable}
                  variant="primary"
                  type="submit"
                >
                  {t("confirm")}
                </Button>
              </div>
            </Form>
          </Row>
        )}
      </CustomModal>

      <CustomModal
        size="md"
        title="Warning"
        show={alreadyBoughtModal}
        onHide={() => {
          setalreadyBoughtModal(false);
        }}
        footer={true}
        handleDelete={() => {
          setalreadyBoughtModal(false);
          setTimeout(() => {
            document
              .querySelector("#details")
              .scrollIntoView({ behavior: "smooth" });
            setIsError(false);
          }, 200);
        }}
      >
        <div>
          <Card.Text>{t("alreadybought")}</Card.Text>
        </div>
      </CustomModal>

      <CustomModal
        size="md"
        title="Warning"
        show={alreadyBoughtModalII}
        onHide={() => {
          setalreadyBoughtModalII(false);
        }}
        footer={true}
        handleDelete={() => {
          setalreadyBoughtModalII(false);
          setTimeout(() => {
            openModal(selectedProperty);
          }, 200);
        }}
      >
        <div>
          <Card.Text>{t("alreadybought")}</Card.Text>
        </div>
      </CustomModal>
      <Footer />
    </section>
  );
}

export default Home;
