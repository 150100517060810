import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export function CustomModal(props) {
  const { size = "lg", isgrid = false, title, handleDelete } = props;
  const { t } = useTranslation();
  return (
    <Modal
      size={size}
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      {isgrid ? (
        <Modal.Body className="show-grid">{props.children}</Modal.Body>
      ) : (
        <Modal.Body>{props.children}</Modal.Body>
      )}
      {props.footer && (
        <Modal.Footer>
          <Button onClick={props.onHide}>{t("no")}</Button>
          <Button style={{ marginLeft: "1rem" }} onClick={handleDelete}>
            {t("yes")}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}
