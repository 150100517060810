import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Carousel,
  Card,
  Form,
  FormControl,
} from "react-bootstrap";
import { CustomModal } from "../../components/customModal/CustomModal";
import _, { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import HorizontalBar from "../../components/horizontalBar/HorizontalBar";
import NavBar from "../../components/navBar/NavBar";
import Footer from "../../components/footer/Footer";
import {
  buyProperty,
  deleteFavourite,
  getAllSalesByUserId,
  getFavouritesById,
  imageURL,
  pdfURL,
} from "../../api/apis";
import AgentPropertyCard from "../../components/agentPropertyCard/AgentPropertyCard";
import LoadingOverlay from "react-loading-overlay";
import GoogleDocsViewer from "react-google-docs-viewer";
import MapBox from "../../components/map/Map";
import { toast } from "react-hot-toast";
import countries from "../../shared/countries.json";

const PdfOrWordViewer = ({ url }) => {
  const { t } = useTranslation();
  const extension = url.match(/\.(pdf|docx)$/)[1];
  if (extension === "pdf") {
    return <GoogleDocsViewer width="600px" height="600px" fileUrl={url} />;
  } else if (extension === "docx") {
    return (
      <Card
        style={{
          width: "15rem",
        }}
      >
        <Card.Img
          variant="top"
          src="https://play-lh.googleusercontent.com/FOIfyL9U8PcmbnfDjcpdUWNZcWLMY24p5SrGERmRr_K-yeqv5ZzjJ81B4ZfmV07kg_Yu"
        />
        <Card.Body style={{ textAlign: "center" }}>
          <Card.Text>{t("documentedfile")}</Card.Text>
          <Button href={url} variant="primary">
            {t("download")}
          </Button>
        </Card.Body>
      </Card>
    );
  } else {
    return <p>Unsupported file type</p>;
  }
};

function UserDashboard() {
  const [salesData, setSalesData] = useState([]);
  const [isOpenViewModal, setIsOpenViewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [propertyType, setPropertyType] = useState("BOUGHT");
  const [favouritesData, setFavouriteData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteProperty, setDeleteProperty] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [counter, setCounter] = useState(5);
  const [isError, setIsError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [buyOptionCountry, setBuyOptionCountry] = useState("");
  const [confirmDisable, setconfirmDisable] = useState(false);

  const [selectedProperty, setSelectedProperty] = useState();

  const { t } = useTranslation();

  const getAllSales = () => {
    const userId = JSON.parse(localStorage.getItem("user")).id;
    getAllSalesByUserId(userId)
      .then((response) => {
        setSalesData(response);
      })
      .catch((err) => {});
  };

  const getAllFavourites = () => {
    const userId = JSON.parse(localStorage.getItem("user")).id;
    getFavouritesById(userId)
      .then((res) => {
        setFavouriteData(res);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getAllSales();
  }, []);

  const openViewModal = (data) => {
    setSelectedProperty(data);
    setIsOpenViewModal((prev) => !prev);
  };

  const hideViewModal = () => {
    setIsOpenViewModal(false);
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteModal(true);
    setDeleteProperty(id);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleDelete = async (id) => {
    try {
      const userId = JSON.parse(localStorage.getItem("user")).id;
      const payload = {
        productDetailsId: id,
        userId: userId,
      };
      await deleteFavourite(payload);
      handleCloseDeleteModal();
      getAllFavourites();
    } catch (error) {}
  };

  const openModal = (data) => {
    console.log({ data });
    setSelectedProperty(data);
    setIsVisible(true);
  };
  const hideModal = () => {
    setIsVisible(false);
  };

  const handleBuyProperty = async (data) => {
    setconfirmDisable(true);
    buyProperty(data)
      .then((res) => {
        hideModal();
        toast.success("Request sent successfully, Agent will contact you.");
        setCounter(5);
        setconfirmDisable(false);
      })
      .catch((err) => {});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (/^\d+$/.test(counter) && counter >= 5 && counter <= 100) {
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        setValidated(true);
      } else {
        const userId = JSON.parse(localStorage.getItem("user")).id;
        const user = JSON.parse(localStorage.getItem("user"));

        const payload = {
          percentage: counter,
          productDetailsId: selectedProperty?.productDetails?.id,
          buyerId: userId,
          agentId: selectedProperty.userId,
          firstname: user?.firstName,
          lastname: user?.lastName,
          email: user?.email,
          phoneNumber: user?.phoneNumber,
          question: e.target[0]?.value,
          address: selectedProperty?.productDetails?.address,
        };
        handleBuyProperty(payload);
      }
    } else {
      setIsError(true);
      document
        .querySelector("#buyButtonQuery")
        .scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section>
      <LoadingOverlay active={loading} spinner text="Loading your content...">
        <NavBar />
        <Container fluid className="dashboard-section" id="home">
          <Row className="dashboard-header-row">
            <Col md={8} className="home-header">
              <span
                style={{
                  paddingBottom: 15,
                  fontSize: "100px",
                  textAlign: "center",
                  color: "#fff",
                }}
                className="heading"
              >
                {t("propertiesyouown")}
              </span>
            </Col>
          </Row>
        </Container>
        <Container
          style={{ minHeight: window.innerHeight - 100 }}
          fluid
          className="home-about-section"
          id="about"
        >
          <Row className="d-flex justify-content-center align-items-center mb-4">
            <Col
              className="d-flex justify-content-center align-items-center"
              md={2}
              xs={4}
              onClick={() => {
                setPropertyType("BOUGHT");
                getAllSales();
              }}
            >
              <div
                className={
                  propertyType === "BOUGHT"
                    ? "SelectedTypeFilterOption"
                    : "TypeFilterOption"
                }
              >
                <div>
                  <span>{t("boughtproperties")}</span>
                </div>
              </div>
            </Col>

            <Col
              className="d-flex justify-content-center align-items-center"
              md={2}
              xs={4}
              onClick={() => {
                setPropertyType("FAVOURITE");
                getAllFavourites();
              }}
            >
              <div
                className={
                  propertyType === "FAVOURITE"
                    ? "SelectedTypeFilterOption"
                    : "TypeFilterOption"
                }
              >
                <div>
                  <span>{t("fovouritebasket")}</span>
                </div>
              </div>
            </Col>
          </Row>
          {propertyType === "BOUGHT" ? (
            <Row
              className="g-4"
              style={{ justifyContent: "center", paddingBottom: "10px" }}
            >
              {!_.isEmpty(salesData) ? (
                salesData?.map((property) => (
                  <Col md={3} xs={12} className="property-card" key={property}>
                    <AgentPropertyCard
                      isAgent={false}
                      images={property?.productDetails?.files}
                      title={property?.productDetails.productName}
                      price={property?.productDetails.price}
                      country={property?.productDetails.country}
                      city={property?.productDetails.city}
                      onClick={() => openViewModal(property)}
                      type={property?.productDetails.productType}
                      isBuy={true}
                      percentage={property?.percentage}
                      isHaveStatus={property?.status}
                    />
                  </Col>
                ))
              ) : (
                <div style={{ marginTop: "5rem" }}>
                  <img
                    alt="not-data"
                    src={require("../../assets/images/no-data.png")}
                    style={{ height: "10rem", width: "10rem" }}
                  />

                  <blockquote className="blockquote mb-0">
                    <p style={{ textAlign: "center", color: "#000" }}>
                      No Properties Available
                    </p>
                  </blockquote>
                </div>
              )}
            </Row>
          ) : (
            <Row
              className="g-4"
              style={{ justifyContent: "center", paddingBottom: "10px" }}
            >
              {!_.isEmpty(favouritesData) ? (
                favouritesData?.map((property) => (
                  <Col md={3} xs={12} className="property-card" key={property}>
                    <AgentPropertyCard
                      isUserDashboard={true}
                      isAgent={false}
                      images={property?.productDetails?.files}
                      title={property?.productDetails.productName}
                      price={property?.productDetails.price}
                      country={property?.productDetails.country}
                      city={property?.productDetails.city}
                      onClick={() => openModal(property)}
                      type={property?.productDetails.productType}
                      onDeleteClick={() =>
                        handleOpenDeleteModal(property?.productDetails?.id)
                      }
                      isShowBuy={true}
                      onClickBuy={() => openModal(property)}
                    />
                  </Col>
                ))
              ) : (
                <div style={{ marginTop: "5rem" }}>
                  <img
                    alt="not-data"
                    src={require("../../assets/images/no-data.png")}
                    style={{ height: "10rem", width: "10rem" }}
                  />

                  <blockquote className="blockquote mb-0">
                    <p style={{ textAlign: "center", color: "#000" }}>
                      No Properties Available
                    </p>
                  </blockquote>
                </div>
              )}
            </Row>
          )}
        </Container>

        <CustomModal
          title={t("showproperty")}
          size="xl"
          show={isOpenViewModal}
          onHide={hideViewModal}
        >
          <Row>
            <Col>
              <Carousel>
                {selectedProperty?.productDetails?.files.map((image) => {
                  return (
                    <Carousel.Item>
                      <img
                        className="d-block w-100 mx-auto"
                        src={
                          !isEmpty(selectedProperty?.productDetails?.files)
                            ? imageURL + image.name
                            : require("../../assets/images/no-image.jpg")
                        }
                        alt="First slide"
                      />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </Col>
            <Col
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              sm={12}
              md={5}
            >
              <Row className="mb-sm-4 mb-xs-4">
                <Card.Text className="PropertyTitleModalView">
                  {selectedProperty?.productDetails?.productName}
                </Card.Text>
                <Card.Text>
                  <i className="fa fa-map-marker GreenColor"></i>{" "}
                  {selectedProperty?.productDetails?.city},{" "}
                  {selectedProperty?.productDetails?.country}
                </Card.Text>
                <Card.Text className="PropertyPrice">
                  {t("dollar")} {selectedProperty?.productDetails?.price}
                </Card.Text>
              </Row>
              <Row>
                <div className="PercentageContainer">
                  <Card.Text className="AvailablePercentage">
                    {t("available")} -{" "}
                    {selectedProperty?.productDetails?.percentge} %
                  </Card.Text>
                  <Card.Text className="AvailablePercentage">
                    {t("youbought")} - {selectedProperty?.percentage}%
                  </Card.Text>
                </div>
              </Row>
            </Col>
          </Row>

          <HorizontalBar />

          <Row>
            <Col>
              <Card.Text className="PropertyDetailsText">
                {t("propertydetails")}
              </Card.Text>
              <Card.Text className="PropertyDetailsText">
                {t("propertydetails")}
              </Card.Text>

              <Card.Text>
                {t("description")} : {selectedProperty?.description}
              </Card.Text>
              <Card.Text>
                {t("roomsAmount")} : {selectedProperty?.roomsAmount}
              </Card.Text>
              <Card.Text>
                {t("squareMeters")} : {selectedProperty?.squareMeters}
              </Card.Text>
              <Card.Text>
                {t("totalFloors")} : {selectedProperty?.totalFloor}
              </Card.Text>
              <Card.Text>
                {t("departmentFloor")} : {selectedProperty?.departmentFloor}
              </Card.Text>
              <Card.Text>
                {t("entryDate")} : {selectedProperty?.entryDate}
              </Card.Text>
            </Col>
          </Row>

          <HorizontalBar />
          <Row>
            <Card.Text className="PropertyDetailsText">
              {t("propertyspecs")}
            </Card.Text>
            {selectedProperty?.productDetails?.ac && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                  marginTop: "0.7rem",
                }}
              >
                <i class="fa fa-snowflake-o" aria-hidden="true"></i>
                <span>{t("ac")}</span>
              </div>
            )}
            {selectedProperty?.productDetails?.balcony && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-home" aria-hidden="true"></i>
                <span>{t("balconythere")}</span>
              </div>
            )}
            {selectedProperty?.productDetails?.elevator && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-tablet" aria-hidden="true"></i>
                <span>{t("elevatorthere")}</span>
              </div>
            )}

            {selectedProperty?.productDetails?.parking && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-product-hunt" aria-hidden="true"></i>
                <span>{t("parkingthere")}</span>
              </div>
            )}

            {selectedProperty?.productDetails?.bar && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-circle-o" aria-hidden="true"></i>
                <span>{t("barthere")}</span>
              </div>
            )}

            {selectedProperty?.productDetails?.warehouse && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-home" aria-hidden="true"></i>
                <span>{t("warehousethere")}</span>
              </div>
            )}
            {selectedProperty?.productDetails?.mmd && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-shield" aria-hidden="true"></i>
                <span>{t("mmdthere")}</span>
              </div>
            )}
            {selectedProperty?.productDetails?.pandora && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-window-maximize" aria-hidden="true"></i>
                <span>{t("pandorathere")}</span>
              </div>
            )}
            {selectedProperty?.productDetails?.disabled && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <i class="fa fa-wheelchair-alt" aria-hidden="true"></i>
                <span>{t("disabledthere")}</span>
              </div>
            )}
          </Row>

          {!_.isEmpty(selectedProperty?.productDetails?.filesPdf) && (
            <>
              <HorizontalBar />
              <Card.Text className="PropertyDetailsText">
                {t("uploadfile")}
              </Card.Text>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2rem",
                }}
              >
                <PdfOrWordViewer
                  url={
                    pdfURL + selectedProperty?.productDetails?.filesPdf[0]?.name
                  }
                />
              </div>
            </>
          )}
        </CustomModal>

        <CustomModal
          size="md"
          title={t("removefavourite")}
          show={deleteModal}
          onHide={handleCloseDeleteModal}
          footer={true}
          handleDelete={() => handleDelete(deleteProperty)}
        >
          <div>
            <Card.Text>{t("removefavouriteconfirm")}</Card.Text>
          </div>
        </CustomModal>

        <CustomModal
          title={t("showproperty")}
          size="xl"
          show={isVisible}
          onHide={hideModal}
        >
          <Row>
            {/* {selectedProperty?.productDetails?.pending && (
              <span
                style={{
                  fontSize: "1rem",
                  color: "red",
                  textAlign: "center",
                  marginBottom: "1rem",
                }}
              >
                {t("alreadybought")}
              </span>
            )} */}
            <Col>
              <Carousel>
                {selectedProperty?.productDetails?.files?.map((image) => {
                  return (
                    <Carousel.Item>
                      <img
                        className="d-block w-100 mx-auto"
                        src={
                          !isEmpty(selectedProperty?.productDetails?.files)
                            ? imageURL + image.name
                            : require("../../assets/images/no-image.jpg")
                        }
                        alt="First slide"
                      />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </Col>
            <Col
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              sm={12}
              md={5}
            >
              <Row id="buyButtonQuery" className="mb-sm-4 mb-xs-4">
                <Card.Text className="PropertyTitleModalView">
                  {selectedProperty?.productDetails?.productName}
                </Card.Text>
                <Card.Text>
                  <i className="fa fa-map-marker GreenColor"></i> &nbsp;
                  {selectedProperty?.productDetails?.city},{" "}
                  {selectedProperty?.productDetails?.country}
                </Card.Text>
                <Card.Text className="PropertyPrice">
                  {t("dollar")} {selectedProperty?.productDetails?.price}
                </Card.Text>
              </Row>

              <div className="BuyCounterDiv">
                {selectedProperty?.productDetails?.percentge !== 0 && (
                  <Row>
                    <div className="CounterDiv">
                      <blockquote
                        style={{ gap: "0.5rem" }}
                        className="blockquote d-flex mb-0"
                      >
                        <p
                          style={{
                            textAlign: "left",
                            color: "#149777",
                            fontSize: "0.8em",
                            fontWeight: "bold",
                          }}
                        >
                          {selectedProperty?.productDetails?.percentge}%{" "}
                          {t("available")}
                        </p>
                      </blockquote>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <FormControl
                          type="number"
                          placeholder="Enter a number between 5 and 100"
                          value={counter}
                          onChange={(e) => setCounter(e.target.value)}
                          min={5}
                          max={100}
                          required
                        />
                        {isError && (
                          <span style={{ fontSize: "0.7rem", color: "red" }}>
                            Percentage should 5 to{" "}
                            {selectedProperty?.productDetails?.percentge}
                          </span>
                        )}
                      </div>
                    </div>
                  </Row>
                )}

                <div
                  style={{ gap: "1rem" }}
                  className="d-flex align-items-center"
                >
                  <Button
                    className={
                      selectedProperty?.productDetails?.percentge === 0
                        ? "SoldButton"
                        : "BuyNowNormal"
                    }
                    disabled={
                      selectedProperty?.productDetails?.percentge === 0
                        ? true
                        : false
                    }
                    onClick={() => {
                      if (
                        /^\d+$/.test(counter) &&
                        counter >= 5 &&
                        counter <= 100
                      ) {
                        document
                          .querySelector("#details")
                          .scrollIntoView({ behavior: "smooth" });
                        setIsError(false);
                      } else {
                        setIsError(true);
                      }
                    }}
                  >
                    {selectedProperty?.productDetails?.percentge === 0
                      ? t("sold")
                      : t("buynow")}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>

          <HorizontalBar />

          <Row>
            <Col>
              <Card.Text className="PropertyDetailsText">
                {t("propertydetails")}
              </Card.Text>
              <Card.Text>
                {selectedProperty?.productDetails?.description}
              </Card.Text>
            </Col>
          </Row>

          <HorizontalBar />
          <Row>
            <Card.Text className="PropertyDetailsText">
              {t("propertyspecs")}
            </Card.Text>
            {selectedProperty?.productDetails?.ac && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                  marginTop: "0.7rem",
                }}
              >
                <i class="fa fa-snowflake-o" aria-hidden="true"></i>
                <span>{t("ac")}</span>
              </div>
            )}
            {selectedProperty?.productDetails?.balcony && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-home" aria-hidden="true"></i>
                <span>{t("balconythere")}</span>
              </div>
            )}
            {selectedProperty?.productDetails?.elevator && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-tablet" aria-hidden="true"></i>
                <span>{t("elevatorthere")}</span>
              </div>
            )}

            {selectedProperty?.productDetails?.parking && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-product-hunt" aria-hidden="true"></i>
                <span>{t("parkingthere")}</span>
              </div>
            )}

            {selectedProperty?.productDetails?.bar && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-circle-o" aria-hidden="true"></i>
                <span>{t("barthere")}</span>
              </div>
            )}

            {selectedProperty?.productDetails?.warehouse && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-home" aria-hidden="true"></i>
                <span>{t("warehousethere")}</span>
              </div>
            )}
            {selectedProperty?.productDetails?.mmd && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-shield" aria-hidden="true"></i>
                <span>{t("mmdthere")}</span>
              </div>
            )}
            {selectedProperty?.productDetails?.pandora && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <i class="fa fa-window-maximize" aria-hidden="true"></i>
                <span>{t("pandorathere")}</span>
              </div>
            )}
            {selectedProperty?.productDetails?.disabled && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <i class="fa fa-wheelchair-alt" aria-hidden="true"></i>
                <span>{t("disabledthere")}</span>
              </div>
            )}
          </Row>

          <Row>
            <HorizontalBar />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <span className="PropertyDetailsText mt-2 mb-4">
                {t("propertylocatedplace")}
              </span>
              <MapBox
                lat={selectedProperty?.productDetails?.longitude}
                lng={selectedProperty?.productDetails?.latitude}
              />
            </div>
          </Row>

          {selectedProperty?.productDetails?.percentge !== 0 && (
            <HorizontalBar />
          )}

          {selectedProperty?.productDetails?.percentge !== 0 && (
            <Row id="details">
              <Card.Text className="PropertyDetailsText">
                {t("fillyourdetails")}
              </Card.Text>
              <Card.Text className="WarningPercentage">
                {t("confirmbeforebuy")}
              </Card.Text>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                {/* <Row md={3} sm={1} xs={1} className="mb-3">
                  <Form.Group
                    as={Col}
                    className="mb-sm-2 mb-md-3 mb-xs-3 mb-3"
                    controlId="formGridEmail"
                  >
                    <Form.Label>{t("firstname")}</Form.Label>
                    <Form.Control
                      required
                      placeholder={t("firstnameplaceholder")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("firstnameerror")}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    className="mb-sm-2 mb-md-3 mb-xs-3 mb-3"
                    controlId="formGridEmail"
                  >
                    <Form.Label>{t("lastname")}</Form.Label>
                    <Form.Control
                      required
                      placeholder={t("lastnameplaceholder")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("lastnameerror")}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    className="mb-sm-2 mb-md-3 mb-xs-3 mb-3"
                    controlId="formGridEmail"
                  >
                    <Form.Label>{t("email")}</Form.Label>
                    <Form.Control required placeholder={t("emailplacehoder")} />
                    <Form.Control.Feedback type="invalid">
                      {t("emailerror")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row> */}

                <Row md={3} sm={1} xs={1} className="mb-3">
                  {/* <Form.Group
                    as={Col}
                    className="mb-sm-2 mb-md-3 mb-xs-3 mb-3"
                    controlId="formGridEmail"
                  >
                    <Form.Label>{t("phonenumber")}</Form.Label>
                    <Form.Control
                      required
                      placeholder={t("phoneplaceholder")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("phonenumbererror")}
                    </Form.Control.Feedback>
                  </Form.Group> */}

                  {/* <Form.Group
                    className="mb-sm-2 mb-md-3 mb-xs-3 mb-3"
                    as={Col}
                    controlId="formGridState"
                  >
                    <Form.Label>{t("yourcountry")}</Form.Label>
                    <Form.Select
                      onChange={(event) => {
                        setBuyOptionCountry(event.target.value);
                      }}
                      required
                    >
                      <option value={""}>{t("selectcountry")}</option>
                      {Object.keys(countries).map((country) => (
                        <option key={country} value={country}>
                          {country}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {t("countryError")}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridState">
                    <Form.Label>{t("yourcity")}</Form.Label>
                    <Form.Select required>
                      <option value={""}>{t("selectcity")}</option>
                      {buyOptionCountry !== "" &&
                        countries[buyOptionCountry].map((city) => (
                          <option key={city} value={city}>
                            {city}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {t("cityError")}
                    </Form.Control.Feedback>
                  </Form.Group> */}
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridState">
                    <Form.Label>{t("anyquestions")}</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder={t("questionplaceholder")}
                    />
                  </Form.Group>
                </Row>
                {console.log({ confirmDisable })}
                <div className="d-flex justify-content-end">
                  <Button
                    disabled={confirmDisable}
                    variant="primary"
                    type="submit"
                  >
                    {t("confirm")}
                  </Button>
                </div>
              </Form>
            </Row>
          )}
        </CustomModal>

        <Footer />
      </LoadingOverlay>
    </section>
  );
}

export default UserDashboard;
